import { remoteAssists } from '@hpx-it/developer-api-client';
import { DeepPartial } from '@hpx-it/hpx-types';
import { RemoteAssistApiContext } from 'api';
import { useRemoteAssistSessionStorage } from 'hooks';
import { clone, isEqual } from 'lodash';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

type RemoteAssistCustomerUpdate = {
  first_name?: string | null;
  last_name?: string | null;
  zip_code?: string | null;
  email?: string | null;
  phone?: string | null;
};

function trimAll(obj: RemoteAssistCustomerUpdate): RemoteAssistCustomerUpdate {
  const trimmedObj = clone(obj);
  Object.entries(trimmedObj).forEach(([key, entry]) => {
    if (typeof entry === 'string') {
      trimmedObj[key as keyof RemoteAssistCustomerUpdate] = entry.trim();
    }
  });
  return trimmedObj;
}

type CustomerEditRemoteAssistCustomer = DeepPartial<
  remoteAssists.RemoteAssist['attendees'][number]
>;

type CustomerEditRemoteAssist = {
  id: string;
  client_id: string;
  supply_client_id: string;
  customer: CustomerEditRemoteAssistCustomer;
  service_location?: string;
};

type UseCustomerEditProps = {
  remoteAssist: CustomerEditRemoteAssist;
  isCurrentSession: boolean;
  onEdit?: () => Promise<unknown>;
};

export function useCustomerEdit({
  remoteAssist,
  isCurrentSession,
  onEdit,
}: UseCustomerEditProps) {
  const { updateRemoteAssist } = useContext(RemoteAssistApiContext);

  const [updateLoading, setUpdateLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // the most up-to-date customer info state
  // stores validated input in CustomerInfoSection
  const [customerUpdate, setCustomerUpdate] =
    useState<RemoteAssistCustomerUpdate | null>(null);

  // current customer values - what is currently saved for the customer
  const currentCustomerValues = useMemo(() => {
    return {
      first_name: remoteAssist.customer.contact?.first_name ?? null,
      last_name: remoteAssist.customer.contact?.last_name ?? null,
      email: remoteAssist.customer.contact?.email ?? null,
      phone: remoteAssist.customer.contact?.phone ?? null,
      zip_code: remoteAssist.service_location ?? null,
    };
  }, [remoteAssist]);

  // the only hook that useRemoteAssistSessionStorge uses is useState
  const [customerUpdateInput, setCustomerUpdateInput] = isCurrentSession
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useRemoteAssistSessionStorage<RemoteAssistCustomerUpdate>(
        remoteAssist.id,
        'customerUpdateInput',
        currentCustomerValues,
      )
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useState<RemoteAssistCustomerUpdate>(currentCustomerValues);

  useEffect(() => {
    setCustomerUpdate(trimAll(customerUpdateInput));
  }, [customerUpdateInput, setCustomerUpdate]);

  const onChange = useCallback(
    (variableName: keyof RemoteAssistCustomerUpdate) =>
      (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string) => {
        const value = typeof event === 'string' ? event : event.target.value;
        const newCustomerUpdateInput = {
          ...customerUpdate,
          [variableName]: value.trim() === '' ? null : value,
        };
        setCustomerUpdateInput(newCustomerUpdateInput);
        return newCustomerUpdateInput;
      },
    [customerUpdate, setCustomerUpdateInput],
  );

  const customerUpdateMatchesCurrent = useMemo(
    () => isEqual(customerUpdate, currentCustomerValues),
    [customerUpdate, currentCustomerValues],
  );

  const updateCustomer = useCallback(async () => {
    try {
      if (!customerUpdate) {
        return;
      }

      setUpdateLoading(true);

      if (customerUpdateMatchesCurrent) {
        return true;
      }

      await updateRemoteAssist({
        id: remoteAssist.id,
        ...(customerUpdate.zip_code && {
          service_location: customerUpdate.zip_code,
        }),
        attendees: [
          {
            contact: {
              ...(customerUpdate.first_name && {
                first_name: customerUpdate.first_name,
              }),
              ...(customerUpdate.last_name && {
                last_name: customerUpdate.last_name,
              }),
              ...(customerUpdate.email && {
                email: customerUpdate.email,
              }),
              ...(customerUpdate.phone && {
                phone: customerUpdate.phone,
              }),
            },
          },
        ],
      });
      setErrorMessage('');
      await onEdit?.();
      return true;
    } catch {
      setErrorMessage('Unable to update customer details');
      return false;
    } finally {
      setUpdateLoading(false);
    }
  }, [
    customerUpdate,
    customerUpdateMatchesCurrent,
    onEdit,
    remoteAssist.id,
    updateRemoteAssist,
  ]);

  return {
    updateCustomer,
    onChange,
    customerUpdateMatchesCurrent,
    updateLoading,
    errorMessage,
    currentCustomerValues,
    customerUpdateInput,
    setCustomerUpdateInput,
  };
}
