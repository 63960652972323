import { useInterval } from '@hpx-it/react-app';
import {
  DependencyList,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

export function usePolling<T>(
  fn: (...args: any) => Promise<T>,
  options: {
    delay: number;
    skip?: boolean;
    dependencies?: DependencyList;
  },
): [
  T | undefined,
  Dispatch<SetStateAction<T | undefined>>,
  () => Promise<T | undefined>,
] {
  const { delay, skip } = options;
  const [data, setData] = useState<T>();

  const fetch = useCallback(async () => {
    if (skip) {
      return;
    }
    const data = await fn();
    setData(data);
    return data;
  }, [fn, skip]);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(options.dependencies ?? [])]);

  useInterval(async () => {
    if (skip) {
      return;
    }

    try {
      await fetch();
    } catch (error) {}
  }, delay);

  return [data, setData, fetch];
}
