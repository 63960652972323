import { DeveloperApiClient, availability } from '@hpx-it/developer-api-client';
import { ReactNode, createContext, useCallback, useContext } from 'react';

import {
  DeveloperApiClientContext,
  throwDeveloperApiUnauthenticatedError,
} from '..';

export type TempOverride = {
  date: string;
  availability: availability.Availability[];
};

type AvailabilityApiContextProps = {
  getAvailability: DeveloperApiClient['getAvailability'];
  getAvailabilityConfig: DeveloperApiClient['getAvailabilityConfig'];
  updateAvailabilityConfig: DeveloperApiClient['updateAvailabilityConfig'];
  getAvailabilityOverrides: DeveloperApiClient['getAvailabilityOverrides'];
  createAvailabilityOverride: DeveloperApiClient['createAvailabilityOverride'];
  updateAvailabilityOverride: DeveloperApiClient['updateAvailabilityOverride'];
  deleteAvailabilityOverride: DeveloperApiClient['deleteAvailabilityOverride'];
};

export type AvailabilityApiProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: AvailabilityApiContextProps = {
  getAvailability: () => throwDeveloperApiUnauthenticatedError(),
  getAvailabilityConfig: () => throwDeveloperApiUnauthenticatedError(),
  updateAvailabilityConfig: () => throwDeveloperApiUnauthenticatedError(),
  getAvailabilityOverrides: () => throwDeveloperApiUnauthenticatedError(),
  createAvailabilityOverride: () => throwDeveloperApiUnauthenticatedError(),
  updateAvailabilityOverride: () => throwDeveloperApiUnauthenticatedError(),
  deleteAvailabilityOverride: () => throwDeveloperApiUnauthenticatedError(),
};

export const AvailabilityApiContext =
  createContext<AvailabilityApiContextProps>(DEFAULT_CONTEXT);

export const AvailabilityApiProvider = ({
  children,
}: AvailabilityApiProviderProps) => {
  const { getDeveloperApiClient } = useContext(DeveloperApiClientContext);

  const getAvailability = useCallback(
    async () => await getDeveloperApiClient().getAvailability(),
    [getDeveloperApiClient],
  );

  const getAvailabilityConfig = useCallback(
    async () => await getDeveloperApiClient().getAvailabilityConfig(),
    [getDeveloperApiClient],
  );

  const updateAvailabilityConfig = useCallback(
    async (input: availability.ClientConfigUpsert) =>
      await getDeveloperApiClient().updateAvailabilityConfig(input),
    [getDeveloperApiClient],
  );

  const getAvailabilityOverrides = useCallback(
    async () => await getDeveloperApiClient().getAvailabilityOverrides(),
    [getDeveloperApiClient],
  );

  const createAvailabilityOverride = useCallback(
    async (input: availability.OverrideCreate) =>
      await getDeveloperApiClient().createAvailabilityOverride(input),
    [getDeveloperApiClient],
  );

  const updateAvailabilityOverride = useCallback(
    async (input: availability.OverrideUpdate) =>
      await getDeveloperApiClient().updateAvailabilityOverride(input),
    [getDeveloperApiClient],
  );

  const deleteAvailabilityOverride = useCallback(
    async (input: availability.OverrideDelete) =>
      await getDeveloperApiClient().deleteAvailabilityOverride(input),
    [getDeveloperApiClient],
  );

  return (
    <AvailabilityApiContext.Provider
      value={{
        getAvailability,
        getAvailabilityConfig,
        updateAvailabilityConfig,
        getAvailabilityOverrides,
        createAvailabilityOverride,
        updateAvailabilityOverride,
        deleteAvailabilityOverride,
      }}
    >
      {children}
    </AvailabilityApiContext.Provider>
  );
};
