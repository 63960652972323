import { RemoteAssistApiContext, TechnicianApiContext } from 'api';
import { usePolling } from 'hooks/usePolling';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { UserRole } from 'utils';

import { UserContext } from './UserContext';
import { technicians, remoteAssists, DeveloperApiClasses } from '@hpx-it/developer-api-client';

type TechnicianContextProps = {
  technician?: DeveloperApiClasses.Technician;
  setTechnician: Dispatch<
    SetStateAction<DeveloperApiClasses.Technician | undefined>
  >;
  currentRemoteAssist?: remoteAssists.RemoteAssist;
  refetch: () => Promise<void>;
  loading: boolean;
};

type TechnicianProviderProps = {
  children: React.ReactNode;
};

const DEFAULT_CONTEXT: TechnicianContextProps = {
  setTechnician: () => {},
  loading: false,
  refetch: async () => {},
};

export const TechnicianContext =
  createContext<TechnicianContextProps>(DEFAULT_CONTEXT);

export const TechnicianProvider = ({ children }: TechnicianProviderProps) => {
  const { isUserAuthenticated, userRoles, userLoading, technicianId } =
    useContext(UserContext);

  const { getTechnician } = useContext(TechnicianApiContext);
  const { getRemoteAssists } = useContext(RemoteAssistApiContext);

  const [technician, setTechnician, refetchTechnician] =
    usePolling<DeveloperApiClasses.Technician>(
      () => getTechnician({ id: technicianId }),
      {
        delay: 30000,
        skip:
          !isUserAuthenticated ||
          !userRoles.includes(UserRole.Technician) ||
          !technicianId,
      },
    );

  const getRemoteAssistWithQuery = useCallback(
    () =>
      getRemoteAssists({
        'technician.id': technician?.id,
        status: ['assigned'],
        page: 1,
      } as remoteAssists.GetRemoteAssistsInput),
    [getRemoteAssists, technician?.id],
  );

  const [currentRemoteAssists, , refetchCurrentRemoteAssists] =
    usePolling<remoteAssists.RemoteAssists>(
      getRemoteAssistWithQuery,
      {
        skip: !technician?.id,
        delay: 30000,
        dependencies: [getRemoteAssistWithQuery],
      },
    );

  const currentRemoteAssist = useMemo(
    () => currentRemoteAssists?.data?.[0] ?? undefined,
    [currentRemoteAssists],
  );

  useEffect(() => {
    if (
      technician?.getVideoChannelStatus() ===
        technicians.VideoStatus.OnCall &&
      !currentRemoteAssist
    ) {
      refetchCurrentRemoteAssists();
    }
  }, [currentRemoteAssist, refetchCurrentRemoteAssists, technician]);

  const refetch = useCallback(async () => {
    await Promise.all([refetchCurrentRemoteAssists(), refetchTechnician()]);
  }, [refetchCurrentRemoteAssists, refetchTechnician]);

  return (
    <TechnicianContext.Provider
      value={{
        technician,
        setTechnician,
        currentRemoteAssist,
        refetch,
        loading: userLoading,
      }}
    >
      {children}
    </TechnicianContext.Provider>
  );
};
