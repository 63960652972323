import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  PaperProps,
  Tab,
  Tabs,
  useTheme,
} from '@mui/material';
import { LabelApiContext } from 'api';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import Draggable from 'react-draggable';

import { EquipmentCard } from './EquipmentCard';
import { labels } from '@hpx-it/developer-api-client';

function PaperComponent(props: PaperProps) {
  return <Paper {...props} />;
}

type handleCreateEquipmentParams = {
  modelNumber?: string;
  serialNumber?: string;
  logo?: string;
};

export type OCRModalDialogProps = {
  url: string;
  attachmentId: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleCreateEquipment: ({
    modelNumber,
    serialNumber,
    logo,
  }: handleCreateEquipmentParams) => void;
};

export function OCRModalDialog({
  url,
  attachmentId,
  isOpen,
  setIsOpen,
  handleCreateEquipment,
}: OCRModalDialogProps) {
  const { getInformation } = useContext(LabelApiContext);
  const [getInformationError, setGetInformationError] = useState(false);
  const [labelInformation, setLabelInformation] =
    useState<labels.GetLabelInformationResponse>();
  const theme = useTheme();

  useEffect(() => {
    (async () => {
      if (isOpen) {
        setGetInformationError(false);
        const response = await getInformation({ attachment_id: attachmentId });
        if (response) {
          setLabelInformation(response);
        } else {
          setGetInformationError(true);
        }
      }
    })();
  }, [attachmentId, getInformation, isOpen, setLabelInformation]);

  return (
    <Draggable>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="lg"
        hideBackdrop // Disable the backdrop color/image
        disableEnforceFocus // Let the user focus on elements outside the dialog
        style={{ pointerEvents: 'none', zIndex: 1000 }} // styles the MuiDialog-container as clickthru-able with pointerEvents
        PaperProps={{
          sx: {
            width: 1000,
            height: '90vh',
            pointerEvents: 'auto', // Set pointerEvents as auto
          },
        }}
        PaperComponent={PaperComponent}
        BackdropProps={{
          style: { backgroundColor: 'white' },
        }}
        onClose={(_event, reason) => {
          if (reason && reason === 'backdropClick') {
            return;
          }
          setIsOpen(false);
        }}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          id="draggable-dialog-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: '14px',
            color: 'white',
            height: '15px',
            backgroundColor: theme.palette.primary.main,
          }}
        >
          Screen Capture
          <IconButton
            style={{ color: 'white' }}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogTitle>
          <Box>
            <Tabs value={0} variant="scrollable" scrollButtons={false}>
              <Tab label="Extract Data" />
            </Tabs>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            bgcolor: theme.palette.grey[200],
          }}
        >
          <Grid
            container
            sx={{
              mt: '1rem',
              mx: 'auto',
            }}
          >
            {/* Start LHS Image Card */}
            <Grid
              container
              item
              xs={6}
              padding="1rem"
              border={`1px solid ${theme.palette.grey[300]}`}
              borderRadius="0.5rem"
              sx={{
                bgcolor: theme.palette.common.white,
              }}
            >
              <img width="100%" height="auto" src={url} alt={''} />
            </Grid>
            {/* End LHS Image Card */}

            {/* Start RHS Equipment Info Card */}
            <EquipmentCard
              labelInformation={labelInformation}
              error={getInformationError}
              handleCreateEquipment={handleCreateEquipment}
            />
            {/* End RHS Equipment Info Card */}
          </Grid>
        </DialogContent>
      </Dialog>
    </Draggable>
  );
}
