import 'tui-image-editor/dist/tui-image-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';

import { PhotoCameraOutlined, PhotoLibrary } from '@mui/icons-material';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { AttachmentApiContext } from 'api';
import { getMediaType } from 'pages/technician-queue-dashboard/component/queue-technician-dashboard/components/TicketDetailsColumn/TicketDetails/TicketDetailsComponents';
import { AttachmentUrl } from 'pages/technician-queue-dashboard/types';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { BORDER } from 'utils';

import { ScreenshotsContainer } from './Screenshots';
import { attachments } from '@hpx-it/developer-api-client';

type AttachmentsProps = {
  selectedRaId: string;
  screenshotURLs: Record<string, AttachmentUrl>;
  setScreenshotURLs: Dispatch<SetStateAction<Record<string, AttachmentUrl>>>;
  pastRA?: boolean;
};

export const Attachments = ({
  selectedRaId,
  screenshotURLs,
  setScreenshotURLs,
  pastRA,
}: AttachmentsProps) => {
  const { getAttachments, getAttachmentUrls } =
    useContext(AttachmentApiContext);
  const [attachmentsPage, setAttachmentsPage] = useState(1);
  const [pagedAttachments, setPagedAttachments] =
    useState<attachments.PagedAttachments>();
  const [attachmentsLoading, setAttachmentsLoading] = useState<boolean>(true);
  const theme: Theme = useTheme();

  useEffect(() => {
    (async () => {
      const response = await getAttachments({
        attached_to_type: 'remote_assist',
        attached_to: selectedRaId,
        page: attachmentsPage,
      });

      if (response) {
        setPagedAttachments(response);
      }

      setAttachmentsLoading(false);
    })();
  }, [attachmentsPage, getAttachments, selectedRaId]);

  useEffect(() => {
    if (pagedAttachments) {
      pagedAttachments.data.forEach(async (attachment) => {
        // download image
        const mediaType = getMediaType(attachment.file_extension);
        const fileUrl = (
          await getAttachmentUrls({ attachment_id: attachment.id })
        ).attachment_urls[0].url;
        if (fileUrl) {
          setScreenshotURLs((screenshotURLs) => {
            return {
              ...screenshotURLs,
              [attachment.id]: {
                id: attachment.id,
                url: fileUrl,
                mediaType: mediaType,
                extension: attachment.file_extension,
              },
            };
          });
        }
      });

      // more attachments to load
      if (
        (pagedAttachments.page + 1) * pagedAttachments.per_page <
        pagedAttachments.total_count
      ) {
        setAttachmentsPage((attachmentsPage) => attachmentsPage + 1);
      }
    }
  }, [getAttachments, getAttachmentUrls, pagedAttachments, setScreenshotURLs]);

  if (attachmentsLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {Object.values(screenshotURLs).length === 0 && !pastRA ? (
        <Grid
          item
          textAlign="center"
          border={BORDER}
          borderRadius="0.219rem"
          margin="1.5rem"
          padding="2rem 0"
        >
          <PhotoCameraOutlined htmlColor={theme.palette.grey[500]} />
          <Typography variant="subtitle1" color={`${theme.palette.grey[500]}`}>
            Screenshots will appear here
          </Typography>
        </Grid>
      ) : Object.values(screenshotURLs).length > 0 && pastRA ? (
        <Grid container direction="column" p={7}>
          <Grid
            container
            sx={{
              backgroundColor: 'white',
              marginTop: '-5rem',
              border: BORDER,
              padding: '0.625rem',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{ padding: '0.625rem' }}
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <PhotoLibrary />
              <Typography
                variant="h6"
                sx={{ marginLeft: '0.5rem', marginTop: '-0.25rem' }}
              >
                Media
              </Typography>
            </Grid>
            <ScreenshotsContainer
              selectedRaId={selectedRaId}
              screenshotURLs={screenshotURLs}
              setScreenshotURLs={setScreenshotURLs}
              pastRA={pastRA}
            />
          </Grid>
        </Grid>
      ) : !pastRA ? (
        <>
          <Grid
            item
            xs={12}
            sx={{ padding: '0rem 1.5rem 1rem 1.5rem' }}
            container
            alignItems="center"
          >
            <Typography variant="h6">Screenshots</Typography>
            <Grid item xs>
              <Tooltip title={'Download all screenshots'}>
                <IconButton
                  size="medium"
                  onClick={() => {
                    if (pagedAttachments) {
                      Object.values(screenshotURLs).forEach(
                        async (screenshotURL) => {
                          if (screenshotURL.mediaType === 'image') {
                            const link = document.createElement('a');
                            link.href = screenshotURL.url;
                            link.setAttribute(
                              'download',
                              screenshotURL.id + screenshotURL.extension,
                            );
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          }
                        },
                      );
                    }
                  }}
                >
                  <GetAppOutlinedIcon fontSize="medium" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid pb="1.5rem" width="100%">
            <ScreenshotsContainer
              selectedRaId={selectedRaId}
              screenshotURLs={screenshotURLs}
              setScreenshotURLs={setScreenshotURLs}
              pastRA={pastRA}
            />
          </Grid>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
