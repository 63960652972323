import { DeveloperApiClient, trades } from '@hpx-it/developer-api-client';
import { ReactNode, createContext, useCallback, useContext } from 'react';

import {
  DeveloperApiClientContext,
  throwDeveloperApiUnauthenticatedError,
} from '..';

type TradeApiContextProps = {
  getTrades: DeveloperApiClient['getTrades'];
  getServiceCodes: DeveloperApiClient['getServiceCodes'];
  getClientServiceCodes: DeveloperApiClient['getClientServiceCodes'];
};

export type TradeApiProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: TradeApiContextProps = {
  getTrades: () => throwDeveloperApiUnauthenticatedError(),
  getServiceCodes: () => throwDeveloperApiUnauthenticatedError(),
  getClientServiceCodes: () => throwDeveloperApiUnauthenticatedError(),
};

export const TradeApiContext =
  createContext<TradeApiContextProps>(DEFAULT_CONTEXT);

export const TradeApiProvider = ({ children }: TradeApiProviderProps) => {
  const { getDeveloperApiClient } = useContext(DeveloperApiClientContext);

  const getTrades = useCallback(
    async (input: trades.GetTrades) =>
      await getDeveloperApiClient().getTrades(input),
    [getDeveloperApiClient],
  );

  const getServiceCodes = useCallback(
    async () => await getDeveloperApiClient().getServiceCodes(),
    [getDeveloperApiClient],
  );

  const getClientServiceCodes = useCallback(
    async (input: trades.GetClientServiceCodesInput) =>
      await getDeveloperApiClient().getClientServiceCodes(input),
    [getDeveloperApiClient],
  );

  return (
    <TradeApiContext.Provider
      value={{ getTrades, getServiceCodes, getClientServiceCodes }}
    >
      {children}
    </TradeApiContext.Provider>
  );
};
