import {
  clients,
  DeveloperApiClasses,
  DeveloperApiClient,
  remoteAssists,
  technicians,
  trades,
} from '@hpx-it/developer-api-client';
import { logger } from '@hpx-it/react-app';

import { generateTheme } from './theme';

const { REACT_APP_HPA_CLIENT_ID } = process.env;

export let TRADE_ID_TO_NAME_MAP: Record<string, string> = {};

const theme = generateTheme();

export let SERVICE_CODE_ID_TO_NAME_MAP: Record<string, string> = {};

export let CURRENT_CLIENT: DeveloperApiClasses.Client;
export let CLIENT_ID_TO_NAME_MAP: Record<string, string> = {};
export let CLIENT_ID_TO_CLIENT_MAP: Record<string, clients.BaseClient> = {};
export const PRODUCT_TYPE_TO_OUTCOMES_MAP: Record<string, string[]> = {};
export const PRODUCT_TYPES = new Set<string>();
export const PRODUCT_OUTCOMES = new Set<string>();
export const getClients = () => Object.values(CLIENT_ID_TO_CLIENT_MAP);
export const getCurrentClient = () => CURRENT_CLIENT;
export const getSupplyClients = () => CURRENT_CLIENT.getSupplyClients() ?? [];
export const hasMultipleSupplyClients = () =>
  CURRENT_CLIENT.hasMultipleSupplyClients();
export const hasHPASupply = () =>
  CURRENT_CLIENT.hasSupplyClient(REACT_APP_HPA_CLIENT_ID);
export const getDemandClients = () => CURRENT_CLIENT.getDemandClients() ?? [];
export const hasMultipleDemandClients = () =>
  CURRENT_CLIENT.hasMultipleDemandClients();
export const isClientOrHasDemandClient = (clientId: string) =>
  CURRENT_CLIENT.isSelf(clientId) || CURRENT_CLIENT.hasDemandClient(clientId);

export let TECHNICIAN_ID_TO_NAME_MAP: Record<string, string> = {};

export const RAS_PER_PAGE = 5;
export const TECHNICIAN_VIDEO_STATUS_NAME_MAP: Record<string, string> = {
  AVAILABLE: 'Available',
  ERROR: 'Error',
  ON_CALL: 'On Call',
  TASK_PENDING: 'Task Pending',
  UNAVAILABLE: 'Unavailable',
};

export const TECHNICIAN_VIDEO_STATUS_RANKING: Record<string, number> = {
  AVAILABLE: 1,
  TASK_PENDING: 2,
  ON_CALL: 3,
  UNAVAILABLE: 4,
  ERROR: 5,
};

export const STATUS_TO_COLOR_MAP: Record<string, string> = {
  assigned: '#FFF5E1',
  canceled: '#FFEDE9',
  pending: '#FFF5E1',
  reserved: '#FFF5E1',
  wrapping: '#FFF5E1',
  scheduled: '#FFF5E1',
  completed: '#E5FAF8',
};

export const STATUS_TO_ICON_MAP: Record<string, string> = {
  assigned: '/static/status-icons/in-progress.svg',
  canceled: '/static/status-icons/cancelled.svg',
  pending: '/static/status-icons/in-progress.svg',
  reserved: '/static/status-icons/in-progress.svg',
  wrapping: '/static/status-icons/in-progress.svg',
  scheduled: '/static/status-icons/in-progress.svg',
  completed: '/static/status-icons/resolved.svg',
};

export const PORTAL_ORDER_TO_RANGE_MAP = {
  ASSIGNED_AT: 'assignedAtRange',
  SCHEDULED_START_AT: 'scheduledStartAtRange',
  CREATED_AT: 'lastStatusChangeAtRange', // TODO: change to createdAtRange once API is updated or remove CREATED_AT from API
  LAST_STATUS_CHANGE_AT: 'lastStatusChangeAtRange',
};

export async function init(
  getClients: DeveloperApiClient['getClients'],
  getClient: DeveloperApiClient['getClient'],
  getTrades: DeveloperApiClient['getTrades'],
  getTechnicians: DeveloperApiClient['getTechnicians'],
  getServiceCodes: DeveloperApiClient['getServiceCodes'],
  getClientsProducts: DeveloperApiClient['getClientsProducts'],
  getClientProductOutcomes: DeveloperApiClient['getClientsProductOutcomes'],
): Promise<void> {
  try {
    await Promise.all([
      (async () => {
        const [client, clients] = await Promise.all([
          getClient(),
          getClients(),
        ]);

        CLIENT_ID_TO_NAME_MAP = {};
        CLIENT_ID_TO_CLIENT_MAP = {};
        CURRENT_CLIENT = client;

        [
          client,
          ...(client.getSupplyClients() ?? []),
          ...(client.getDemandClients() ?? []),
          ...clients.data,
        ].forEach((client) => {
          CLIENT_ID_TO_NAME_MAP[client.id] = client.name;
          CLIENT_ID_TO_CLIENT_MAP[client.id] = client;
        });

        const productTypes = await getClientsProducts({ client_id: client.id });
        productTypes.products.forEach((product) => PRODUCT_TYPES.add(product));
        await Promise.all(
          productTypes.products.map((productType) =>
            (async () => {
              const outcomes = (
                await getClientProductOutcomes({
                  client_id: client.id,
                  product: productType,
                })
              ).product_outcomes;
              PRODUCT_TYPE_TO_OUTCOMES_MAP[productType] = outcomes;
              outcomes.forEach((outcome) => PRODUCT_OUTCOMES.add(outcome));
            })(),
          ),
        );
      })(),
      (async () => {
        const trades: trades.Trade[] = (await getTrades({})) ?? [];
        TRADE_ID_TO_NAME_MAP = {};
        for (const trade of trades) {
          TRADE_ID_TO_NAME_MAP[trade.id] = trade.name;
        }
      })(),
      (async () => {
        const serviceCodes: trades.ServiceCode[] =
          (await getServiceCodes()) ?? [];
        SERVICE_CODE_ID_TO_NAME_MAP = {};
        for (const serviceCode of serviceCodes) {
          SERVICE_CODE_ID_TO_NAME_MAP[serviceCode.id] = serviceCode.name;
        }
      })(),
      (async () => {
        const technicians: DeveloperApiClasses.Technicians =
          (await getTechnicians({})) ?? [];
        TECHNICIAN_ID_TO_NAME_MAP = {};
        for (const tech of technicians.data) {
          TECHNICIAN_ID_TO_NAME_MAP[tech.id] = tech.name;
        }
      })(),
    ]);
  } catch (error) {
    logger.error(
      'Received error while mapping clients, trades, service codes, and technicians',
      { message: error },
    );
  }
}

export const STATUS_TYPES = [
  technicians.TechnicianStatus.Available,
  technicians.TechnicianStatus.Unavailable,
  technicians.TechnicianStatus.Offline,
];
export const technicianStatusColor = (status: technicians.TechnicianStatus) => {
  switch (status) {
    case technicians.TechnicianStatus.Available:
      return 'lawngreen';
    case technicians.TechnicianStatus.Unavailable:
      return 'orangered';
    case technicians.TechnicianStatus.Offline:
      return 'lightgrey';
  }
};

export const technicianVideoStatusColor = (
  videoStatus: technicians.VideoStatus,
) => {
  switch (videoStatus) {
    case technicians.VideoStatus.Available:
      return '#19BD3A';
    case technicians.VideoStatus.OnCall:
      return '#F16E00';
    case technicians.VideoStatus.TaskPending:
      return '#F16E00';
    case technicians.VideoStatus.Error:
      return theme.palette.grey[500];
    case technicians.VideoStatus.Unavailable:
      return theme.palette.grey[500];
  }
};

export const remoteAssistStatusColor = (
  status: remoteAssists.RemoteAssist['status'],
) => {
  switch (status) {
    case 'completed':
      return '#21998d';
    default:
      return '#C43256';
  }
};

export const mapAffiliateKeyToDisplayName = (affiliateKey: string) => {
  switch (affiliateKey) {
    case 'fpl':
      return 'FPL';
    default:
      logger.error(
        `Display name for affiliate key '${affiliateKey}' not found.`,
      );
      return affiliateKey;
  }
};

export const TOP_NAV_HEIGHT = '5rem';
export const LEFT_NAV_WIDTH = '4.5rem';
export const LEFT_NAV_WIDTH_EXPANDED = '16.25rem';

export const ICON_COLOR = theme.palette.grey[500];
export const BORDER = `1px solid ${theme.palette.grey[300]}`;

export const ALL_CLIENTS_FILTER_OPTION = 'All Clients';
