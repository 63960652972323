import { carouselItemStyle } from 'pages/technician-queue-dashboard/component/queue-technician-dashboard/components/TicketDetailsColumn/TicketDetails/TicketDetailsComponents/TicketImageCarousel/style';
import { AttachmentUrl } from 'pages/technician-queue-dashboard/types';
import { Dispatch, SetStateAction } from 'react';
import Carousel from 'react-material-ui-carousel';

import { Screenshot } from '.';

export const SCREENSHOTS_CONTAINER_ID = 'screenshots';

type ScreenshotsContainerProps = {
  selectedRaId: string;
  screenshotURLs: Record<string, AttachmentUrl>;
  setScreenshotURLs: Dispatch<SetStateAction<Record<string, AttachmentUrl>>>;
  pastRA?: boolean;
};

export const ScreenshotsContainer = ({
  selectedRaId,
  screenshotURLs,
  setScreenshotURLs,
  pastRA,
}: ScreenshotsContainerProps): JSX.Element => {
  return (
    <Carousel
      autoPlay={false}
      navButtonsAlwaysVisible={true}
      animation="fade"
      swipe={false}
      height="calc(10rem - 29px)"
    >
      {Object.entries(screenshotURLs).map(([id, attachmentUrl], index) => (
        <div key={index} style={carouselItemStyle}>
          <Screenshot
            id={id}
            url={attachmentUrl.url}
            mediaType={attachmentUrl.mediaType}
            selectedRaId={selectedRaId}
            setScreenshotURLs={setScreenshotURLs}
            pastRA={pastRA}
          />
        </div>
      ))}
    </Carousel>
  );
};
