import 'tui-image-editor/dist/tui-image-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';

import { logger } from '@hpx-it/react-app';
import { AutoFixHighOutlined, Close, EditOutlined } from '@mui/icons-material';
import { PlayCircleFilled } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popper,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
// @ts-ignore
import ImageEditor from '@toast-ui/react-image-editor';
import { AttachmentApiContext } from 'api';
import { TechnicianContext, UserContext } from 'contexts';
import { CreateEquipmentContext } from 'contexts';
import { getMediaType } from 'pages/technician-queue-dashboard/component/queue-technician-dashboard/components/TicketDetailsColumn/TicketDetails/TicketDetailsComponents';
import {
  AttachmentUrl,
  MediaType,
} from 'pages/technician-queue-dashboard/types';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { pushToastMessage } from 'utils';

import { OCRModal } from './OCRModal';
import {
  getConfirmCloseDialogButtonStyle,
  getEditAndSaveScreenshotButtonStyle,
  getImageEditorBoxStyle,
  getImageEditorButtonStyle,
  getImageEditorStyle,
  getScreenshotBoxStyle,
  getScreenshotDialogIconStyle,
  getScreenshotDialogMenuItemStyle,
  getScreenshotDialogMenuListStyle,
  getScreenshotImageDialogStyle,
  getScreenshotImagePopperStyle,
  getScreenshotImageStyle,
  getScreenshotPlayCirleStyle,
  getScreenshotVideoDialogStyle,
  getScreenshotVideoDivStyle,
  getScreenshotVideoStyle,
} from './style';
import { diagnosis } from '@hpx-it/developer-api-client';

type ScreenshotProps = {
  id: string;
  url: string;
  mediaType: MediaType;
  selectedRaId: string;
  setScreenshotURLs: Dispatch<SetStateAction<Record<string, AttachmentUrl>>>;
  pastRA?: boolean;
};
export const Screenshot = ({
  id,
  url,
  mediaType,
  selectedRaId,
  setScreenshotURLs,
  pastRA,
}: ScreenshotProps) => {
  const { token } = useContext(UserContext);
  const { setEquipmentToCreate } = useContext(CreateEquipmentContext);
  const { uploadAttachment } = useContext(AttachmentApiContext);
  const { technician } = useContext(TechnicianContext);
  const theme = useTheme();

  const [dropdownAnchorElement, setDropdownAnchorElement] =
    useState<null | HTMLElement>(null);
  const [mouseOnMenu, setMouseOnMenu] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [confirmCloseDialogOpen, setConfirmCloseDialogOpen] = useState(false);
  const previousAnchor = useRef<HTMLElement | null>(null);
  const handleClose = useCallback(() => {
    if (changesMade) {
      setConfirmCloseDialogOpen(true);
    } else {
      setEditDialogOpen(false);
    }
  }, [changesMade]);
  const [ocrOpen, setOcrOpen] = useState(false);
  const [isImageZoomed, setIsImageZoomed] = useState(false);
  const [attachmentsApiLoading, setAttachmentsApiLoading] = useState(false);

  type handleCreateEquipmentParams = {
    modelNumber?: string;
    serialNumber?: string;
    logo?: string;
  };

  const handleCreateEquipment = ({
    modelNumber,
    serialNumber,
    logo,
  }: handleCreateEquipmentParams) => {
    const createdEquipment: diagnosis.Equipment = {
      name: '',
      type: '',
      brand: logo ?? '',
      model_number: modelNumber ?? '',
      serial_number: serialNumber ?? '',
      error_code: '',
      equipment_notes: '',
    };
    setEquipmentToCreate(createdEquipment);

    if (pastRA === false) {
      setOcrOpen(false);
      pushToastMessage({
        content: 'Equipment created and visible in the Equipment Tab',
        variant: 'success',
        autohide: 6000,
      });
    } else {
      pushToastMessage({
        content: 'Equipment can only be created within a video session',
        variant: 'info',
        autohide: 5000,
      });
    }
  };

  const imageRef = useRef<null | any>();
  const currentRef = useRef<null | any>();

  const editAndSaveScreenshot = async () => {
    const instance = imageRef.current.getInstance();
    const dataURL = instance.toDataURL();
    const response = await fetch(dataURL);
    const blob = await response.blob();
    const file = new File([blob], 'screenshot.jpg', {
      type: 'image/jpeg',
    });
    const input = {
      attached_to_type: 'remote_assist' as const,
      attached_to: selectedRaId,
      file,
      user_type: 'technician' as const,
      user_id: technician?.id,
    };

    const uploadEditedScreenshot = async () => {
      setAttachmentsApiLoading(true);
      try {
        const uploadedAttachment = await uploadAttachment(input);
        if (uploadedAttachment) {
          setScreenshotURLs((screenshotURLs) => {
            return {
              ...screenshotURLs,
              [uploadedAttachment.id]: {
                id: uploadedAttachment.id,
                url: URL.createObjectURL(blob),
                mediaType: getMediaType(uploadedAttachment.file_extension),
                extension: uploadedAttachment.file_extension,
              },
            };
          });
          pushToastMessage({
            content: 'Edited screenshot saved',
            variant: 'success',
            autohide: 5000,
          });
        }
        setEditDialogOpen(false);
      } catch (error) {
        logger.error('Failed to save edited screenshot', { message: error });
        pushToastMessage({
          content: 'Unable to save edited screenshot',
          variant: 'error',
          action: {
            text: 'retry',
            function: uploadEditedScreenshot,
          },
        });
      } finally {
        setAttachmentsApiLoading(false);
      }
    };

    uploadEditedScreenshot();
  };

  return (
    <>
      <Box {...getScreenshotBoxStyle(theme)}>
        {mediaType === 'video' && (
          <div {...getScreenshotVideoDivStyle()}>
            <video
              ref={currentRef}
              onMouseEnter={(event) => {
                setDropdownAnchorElement(event.currentTarget);
              }}
              onMouseLeave={(event) => {
                previousAnchor.current = dropdownAnchorElement;
                setDropdownAnchorElement(null);
              }}
              onClick={() => {
                setIsImageZoomed(true);
              }}
              src={url}
              {...getScreenshotVideoStyle()}
            />
            <PlayCircleFilled {...getScreenshotPlayCirleStyle()} />
          </div>
        )}
        {mediaType === 'image' && (
          <img
            onMouseEnter={(event) => {
              setDropdownAnchorElement(event.currentTarget);
            }}
            onMouseLeave={() => {
              previousAnchor.current = dropdownAnchorElement;
              setDropdownAnchorElement(null);
            }}
            onClick={() => {
              setIsImageZoomed(true);
            }}
            src={url}
            alt={'screenshot'}
            {...getScreenshotImageStyle()}
          />
        )}
        <Dialog
          open={isImageZoomed}
          onClose={() => {
            setIsImageZoomed(false);
          }}
          fullWidth
        >
          <IconButton
            onClick={() => {
              setIsImageZoomed(false);
            }}
            {...getScreenshotDialogIconStyle(theme)}
          >
            <Close />
          </IconButton>
          {mediaType === 'video' ? (
            <video src={url} {...getScreenshotVideoDialogStyle()} />
          ) : (
            <img
              src={url}
              alt={'screenshot'}
              {...getScreenshotImageDialogStyle()}
            />
          )}
        </Dialog>
        {mediaType === 'image' && (
          <Popper
            style={{ zIndex: 200 }}
            onMouseEnter={() => {
              setMouseOnMenu(true);
            }}
            onMouseLeave={() => {
              setMouseOnMenu(false);
            }}
            anchorEl={
              !!dropdownAnchorElement
                ? dropdownAnchorElement
                : previousAnchor.current
            }
            open={!!dropdownAnchorElement || mouseOnMenu}
            {...getScreenshotImagePopperStyle()}
          >
            <MenuList {...getScreenshotDialogMenuListStyle(theme)}>
              <MenuItem
                onClick={() => {
                  setEditDialogOpen(true);
                  setDropdownAnchorElement(null);
                }}
                {...getScreenshotDialogMenuItemStyle(theme)}
              >
                <ListItemIcon>
                  <EditOutlined fontSize="small" />
                </ListItemIcon>
                <ListItemText>Markup Image</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOcrOpen(true);
                  setDropdownAnchorElement(null);
                }}
                {...getScreenshotDialogMenuItemStyle(theme)}
              >
                <ListItemIcon>
                  <AutoFixHighOutlined fontSize="small" />
                </ListItemIcon>
                <ListItemText> Extract Data </ListItemText>
              </MenuItem>
            </MenuList>
          </Popper>
        )}
      </Box>
      <Dialog
        open={editDialogOpen}
        onClose={handleClose}
        disableEnforceFocus={true}
        maxWidth={false}
      >
        <Box {...getImageEditorBoxStyle()}>
          <ImageEditor
            key={'img'}
            ref={imageRef}
            {...getImageEditorStyle(url)}
            onUndoStackChanged={(length: number) => {
              setChangesMade(length !== 0);
            }}
          />
          <IconButton
            onClick={handleClose}
            {...getImageEditorButtonStyle(theme)}
          >
            <Tooltip title="Close">
              <Close color="inherit" />
            </Tooltip>
          </IconButton>
          <Button
            onClick={() => editAndSaveScreenshot()}
            disabled={attachmentsApiLoading || !changesMade}
            {...getEditAndSaveScreenshotButtonStyle()}
          >
            SAVE
          </Button>
        </Box>
      </Dialog>
      <Dialog
        open={confirmCloseDialogOpen}
        onClose={() => setConfirmCloseDialogOpen(false)}
      >
        <Box p={2}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography variant="h6">Closing Screenshot Edit</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                You are closing Screenshot Edit with changes made to the
                screenshot. Are you sure you wish to close without saving?
              </Typography>
            </Grid>
            <Grid item container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  color="secondary"
                  onClick={() => setConfirmCloseDialogOpen(false)}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  {...getConfirmCloseDialogButtonStyle()}
                  onClick={() => {
                    setConfirmCloseDialogOpen(false);
                    setEditDialogOpen(false);
                  }}
                >
                  Close without saving
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      <OCRModal
        token={token ?? ''}
        url={url}
        attachmentId={id}
        isOpen={ocrOpen}
        setIsOpen={setOcrOpen}
        handleCreateEquipment={handleCreateEquipment}
      />
    </>
  );
};
