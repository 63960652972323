import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserRole, View } from 'utils';

import { views as adminPortalViews } from '../pages/admin-portal';
import { views as bookingViews } from '../pages/booking';
import { views as remoteAssistCallViews } from '../pages/remote-assist-call';
import { views as remoteAssistQueueCallViews } from '../pages/remote-assist-queue-call';
import { views as remoteAssistsViews } from '../pages/remote-assists';
import { views as settingsViews } from '../pages/settings';
import { views as technicianQueueDashboardViews } from '../pages/technician-queue-dashboard';
import { UserContext } from './UserContext';

type ViewsContextProps = {
  views: View[];
  currentView?: View;
  showNav: boolean;
};

type ViewsProviderProps = {
  children: React.ReactNode;
};

const roleToRedirectViewId: { role: UserRole; viewId: string }[] = [
  { role: UserRole.Technician, viewId: 'technician-queue-dashboard' },
  { role: UserRole.Admin, viewId: 'remote-assists' },
  { role: UserRole.Agent, viewId: 'remote-assists' },
];

const DEFAULT_CONTEXT: ViewsContextProps = {
  views: [],
  showNav: true,
};

const matchPath = (path: string, currentPath: string): boolean => {
  const pathParts = path.split('/').filter(Boolean);
  const currentPathParts = currentPath.split('/').filter(Boolean);

  if (pathParts.length !== currentPathParts.length) {
    return false;
  }

  for (let i = 0; i < currentPathParts.length; i++) {
    if (!pathParts[i].startsWith(':') && pathParts[i] !== currentPathParts[i]) {
      return false;
    }
  }

  return true;
};

export const ViewsContext = createContext<ViewsContextProps>(DEFAULT_CONTEXT);

export const ViewsProvider = ({ children }: ViewsProviderProps) => {
  const { userRoles } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentView, setCurrentView] = useState<View>();
  const views = useMemo(
    () =>
      [
        ...remoteAssistCallViews,
        ...remoteAssistQueueCallViews,
        ...technicianQueueDashboardViews,
        ...remoteAssistsViews,
        ...adminPortalViews,
        ...bookingViews,
        ...settingsViews,
      ].filter(
        (view) =>
          view.checker?.({
            userRoles,
            entitlements: {},
          }) ?? true,
      ),
    [userRoles],
  );

  useEffect(() => {
    setCurrentView(
      views.find((view) => matchPath(view.path, location.pathname)),
    );
  }, [location, views]);

  useEffect(() => {
    if (
      !views.find((view) => matchPath(view.path, location.pathname)) &&
      !location.pathname.startsWith('/account')
    ) {
      const viewId = roleToRedirectViewId.find(({ role }) =>
        userRoles.includes(role),
      )?.viewId;
      navigate(views.find((view) => view.id === viewId)?.path ?? views[0].path);
    }
  }, [navigate, location, userRoles, views]);

  return (
    <ViewsContext.Provider
      value={{
        views,
        currentView,
        showNav: currentView?.showNav ?? true,
      }}
    >
      {children}
    </ViewsContext.Provider>
  );
};
