import { diagnosis, remoteAssists } from '@hpx-it/developer-api-client';
import { SetValueFunction, useRemoteAssistSessionStorage } from 'hooks';
import { omit } from 'lodash';
import { createContext, useMemo, useState } from 'react';

type RemoteAssistCallContextProps = {
  remoteAssist: remoteAssists.RemoteAssist;
  productOutcome: string;
  setProductOutcome: React.Dispatch<React.SetStateAction<string>>;
  productType: string;
  setProductType: React.Dispatch<React.SetStateAction<string>>;
  reference: string;
  setReference: SetValueFunction<string>;
  remoteAssistEquipment: diagnosis.Equipment[];
  setRemoteAssistEquipment: SetValueFunction<diagnosis.Equipment[]>;
  sessionEnded: boolean;
  setSessionEnded: React.Dispatch<React.SetStateAction<boolean>>;
  remoteAssistAcceptedAt: string;
  sessionCompleted: boolean;
  setSessionCompleted: React.Dispatch<React.SetStateAction<boolean>>;
};

type RemoteAssistCallProviderProps = {
  remoteAssist: remoteAssists.RemoteAssist;
  children: React.ReactNode;
};

const DEFAULT_CONTEXT: RemoteAssistCallContextProps = {
  remoteAssist: '' as never,
  productOutcome: '',
  setProductOutcome: () => {},
  productType: '',
  setProductType: () => {},
  reference: '',
  setReference: () => {},
  remoteAssistEquipment: [],
  setRemoteAssistEquipment: () => {},
  sessionEnded: false,
  setSessionEnded: () => {},
  remoteAssistAcceptedAt: '0',
  sessionCompleted: false,
  setSessionCompleted: () => {},
};

export const RemoteAssistCallContext =
  createContext<RemoteAssistCallContextProps>(DEFAULT_CONTEXT);

export const RemoteAssistCallProvider = ({
  remoteAssist,
  children,
}: RemoteAssistCallProviderProps) => {
  const [productOutcome, setProductOutcome] = useState<string>(
    remoteAssist.product?.outcome ?? '',
  );
  const [productType, setProductType] = useState<string>(
    remoteAssist.product?.type ?? '',
  );
  const [sessionEnded, setSessionEnded] = useState<boolean>(false);
  const [sessionCompleted, setSessionCompleted] = useState<boolean>(false);

  const [reference, setReference] = useRemoteAssistSessionStorage<string>(
    remoteAssist.id,
    'reference',
    remoteAssist.reference ?? '',
  );

  const [remoteAssistEquipment, setRemoteAssistEquipment] =
    useRemoteAssistSessionStorage<diagnosis.Equipment[]>(
      remoteAssist?.id ?? '',
      'remoteAssistEquipment',
      remoteAssist.diagnosis?.equipment?.map((equipment) =>
        omit(equipment, ['__typename']),
      ) ?? [],
    );
  const remoteAssistAcceptedAt = useMemo(
    () => new Date().toISOString(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [remoteAssist.id],
  );

  return (
    <RemoteAssistCallContext.Provider
      value={{
        remoteAssist,
        productOutcome,
        setProductOutcome,
        productType,
        setProductType,
        reference,
        setReference,
        remoteAssistEquipment,
        setRemoteAssistEquipment,
        sessionEnded,
        setSessionEnded,
        remoteAssistAcceptedAt,
        sessionCompleted,
        setSessionCompleted,
      }}
    >
      {children}
    </RemoteAssistCallContext.Provider>
  );
};
