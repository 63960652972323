import { DeveloperApiClient, comments } from '@hpx-it/developer-api-client';
import { ReactNode, createContext, useCallback, useContext } from 'react';

import {
  DeveloperApiClientContext,
  throwDeveloperApiUnauthenticatedError,
} from '..';

type CommentsApiContextProps = {
  getComments: DeveloperApiClient['getComments'];
  addComments: DeveloperApiClient['addCommentsOnParent'];
};

export type CommentsApiProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: CommentsApiContextProps = {
  getComments: () => throwDeveloperApiUnauthenticatedError(),
  addComments: () => throwDeveloperApiUnauthenticatedError(),
};

export const CommentsApiContext =
  createContext<CommentsApiContextProps>(DEFAULT_CONTEXT);

export const CommentsApiProvider = ({ children }: CommentsApiProviderProps) => {
  const { getDeveloperApiClient } = useContext(DeveloperApiClientContext);

  const getComments = useCallback(
    async (parent_id) =>
      await getDeveloperApiClient().getComments(parent_id, 0, 100, {
        'filter.parent_id': parent_id,
        'filter.parent_type': 'ticket',
      }),
    [getDeveloperApiClient],
  );

  const addComments = useCallback(
    async (
      parent_id: string,
      parent_type: comments.CommentParentType,
      comments: comments.CommentAddInput[],
    ) =>
      await getDeveloperApiClient().addCommentsOnParent(
        parent_id,
        parent_type,
        comments,
      ),
    [getDeveloperApiClient],
  );

  return (
    <CommentsApiContext.Provider value={{ getComments, addComments }}>
      {children}
    </CommentsApiContext.Provider>
  );
};
