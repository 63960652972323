import { DeveloperApiClient, clients } from '@hpx-it/developer-api-client';
import { ReactNode, createContext, useCallback, useContext } from 'react';

import {
  DeveloperApiClientContext,
  throwDeveloperApiUnauthenticatedError,
} from '..';

type ClientApiContextProps = {
  getClients: DeveloperApiClient['getClients'];
  getClient: DeveloperApiClient['getClient'];
  updateClient: DeveloperApiClient['updateClient'];
  getClientsProducts: DeveloperApiClient['getClientsProducts'];
  getClientsProductOutcomes: DeveloperApiClient['getClientsProductOutcomes'];
};

export type ClientApiProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: ClientApiContextProps = {
  getClients: () => throwDeveloperApiUnauthenticatedError(),
  getClient: () => throwDeveloperApiUnauthenticatedError(),
  updateClient: () => throwDeveloperApiUnauthenticatedError(),
  getClientsProducts: () => throwDeveloperApiUnauthenticatedError(),
  getClientsProductOutcomes: () => throwDeveloperApiUnauthenticatedError(),
};

export const ClientApiContext =
  createContext<ClientApiContextProps>(DEFAULT_CONTEXT);

export const ClientApiProvider = ({ children }: ClientApiProviderProps) => {
  const { getDeveloperApiClient } = useContext(DeveloperApiClientContext);

  const getClients = useCallback(
    async () => await getDeveloperApiClient().getClients(),
    [getDeveloperApiClient],
  );

  const getClient = useCallback(
    async () => await getDeveloperApiClient().getClient(),
    [getDeveloperApiClient],
  );

  const updateClient = useCallback(
    async (input: clients.UpdateClient) =>
      await getDeveloperApiClient().updateClient(input),
    [getDeveloperApiClient],
  );

  const getClientsProducts = useCallback(
    async (input: clients.GetClientsProducts) =>
      await getDeveloperApiClient().getClientsProducts(input),
    [getDeveloperApiClient],
  );

  const getClientsProductOutcomes = useCallback(
    async (input: clients.GetClientsProductOutcomes) =>
      await getDeveloperApiClient().getClientsProductOutcomes(input),
    [getDeveloperApiClient],
  );

  return (
    <ClientApiContext.Provider
      value={{
        getClients,
        getClient,
        updateClient,
        getClientsProducts,
        getClientsProductOutcomes,
      }}
    >
      {children}
    </ClientApiContext.Provider>
  );
};
