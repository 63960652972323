import { PlayCircleFilled } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  MenuList,
  Popper,
  Theme,
} from '@mui/material';
import { carouselImageStyle } from 'pages/technician-queue-dashboard/component/queue-technician-dashboard/components/TicketDetailsColumn/TicketDetails/TicketDetailsComponents/TicketImageCarousel/style';
import {
  ComponentProps,
  DetailedHTMLProps,
  HTMLAttributes,
  ImgHTMLAttributes,
  VideoHTMLAttributes,
} from 'react';

// @ts-expect-error: ImageEditor does not have TypeScript definitions
import ImageEditor from '@toast-ui/react-image-editor';

export const getScreenshotBoxStyle = (
  theme: Theme,
): ComponentProps<typeof Box> => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  sx: {
    backgroundColor: theme.palette.common.black,
    '>div': {
      display: 'none',
    },
    ':hover': {
      cursor: 'pointer',
      '>img': {
        opacity: '0.7',
      },
      '>div': {
        display: 'flex',
      },
    },
  },
});

export const getScreenshotVideoDivStyle = (): DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> => ({
  style: { display: 'contents', position: 'relative' },
});

export const getScreenshotVideoStyle = () => ({
  width: 'auto',
  height: 150,
  controls: false,
  style: carouselImageStyle,
});

export const getScreenshotPlayCirleStyle = (): ComponentProps<
  typeof PlayCircleFilled
> => ({
  style: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '3rem',
    color: 'black',
    pointerEvents: 'none',
    zIndex: 1,
  },
});

export const getScreenshotImageStyle = () => ({
  width: 'inherit',
  height: 150,
  style: carouselImageStyle,
});

export const getScreenshotDialogIconStyle = (theme: Theme) => ({
  sx: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
  },
});

export const getScreenshotVideoDialogStyle = (): DetailedHTMLProps<
  VideoHTMLAttributes<HTMLVideoElement>,
  HTMLVideoElement
> => ({
  controls: true,
  style: {
    maxWidth: '100%',
    maxHeight: '90vh',
    objectFit: 'contain',
    display: 'block',
    margin: 'auto',
  },
});

export const getScreenshotImageDialogStyle = (): DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> => ({
  style: {
    maxWidth: '100%',
    maxHeight: '90vh',
    objectFit: 'contain',
    display: 'block',
    margin: 'inherit',
  },
});

export const getScreenshotImagePopperStyle = (): Partial<
  ComponentProps<typeof Popper>
> => ({
  placement: 'bottom',
  modifiers: [
    {
      name: 'arrow',
      enabled: true,
    },
  ],
});

export const getScreenshotDialogMenuListStyle = (
  theme: Theme,
): ComponentProps<typeof MenuList> => ({
  sx: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 1,
  },
});

export const getScreenshotDialogMenuItemStyle = (
  theme: Theme,
): ComponentProps<typeof MenuItem> => ({
  sx: {
    ':hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
});

export const getImageEditorBoxStyle = (): ComponentProps<typeof Box> => ({
  sx: {
    position: 'relative',
    width: 'auto',
    bgcolor: '#151515',
    justifyContent: 'center',
  },
});

export const getImageEditorStyle = (
  url: string,
): ComponentProps<typeof ImageEditor> => ({
  includeUI: {
    loadImage: {
      path: url,
      name: 'SampleImage',
    },
    theme: {
      'header.display': 'none',
    },
    menu: ['crop', 'rotate', 'draw', 'shape', 'text'],
    uiSize: {
      width: '1000px',
      height: '700px',
    },
    menuBarPosition: 'bottom',
  },
  cssMaxHeight: 400,
  cssMaxWidth: 600,
  selectionStyle: {
    cornerSize: 20,
    rotatingPointOffset: 70,
  },
});

export const getImageEditorButtonStyle = (
  theme: Theme,
): ComponentProps<typeof IconButton> => ({
  sx: {
    color: '#8a8a8a',
    bgcolor: 'rgba(255,255,255,0.06)',
    ':hover': {
      color: theme.palette.common.white,
      bgcolor: 'rgba(255,255,255,0.06)',
    },
    position: 'absolute',
    margin: '8px',
    top: '0',
    left: '0',
    zIndex: 5,
  },
});

export const getEditAndSaveScreenshotButtonStyle = (): ComponentProps<
  typeof Button
> => ({
  variant: 'contained',
  color: 'secondary',
  sx: {
    position: 'absolute',
    margin: '15px',
    bottom: '0',
    right: '0',
    zIndex: 5,
  },
});

export const getConfirmCloseDialogButtonStyle = (): ComponentProps<
  typeof Button
> => ({ color: 'secondary', variant: 'contained' });
