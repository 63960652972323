import { Theme, createTheme } from '@mui/material';

export const DEFAULT_PRIMARY_COLOR = '#1A3F6C';
export const DEFAULT_SECONDARY_COLOR = '#019592';

export const generateTheme = (
  primaryColor = DEFAULT_PRIMARY_COLOR,
  secondaryColor?: string,
): Theme => {
  const mainSecondaryColor =
    primaryColor === DEFAULT_PRIMARY_COLOR
      ? DEFAULT_SECONDARY_COLOR
      : (secondaryColor ?? primaryColor);

  return createTheme({
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: mainSecondaryColor,
      },
      info: { main: '#A2B5F5', light: '#C6E0F7' },
      error: { main: '#f44336' },
      warning: { main: '#ffa726' },
      success: { main: '#66bb6a', light: '#B7F791' },
    },
  });
};
