import { remoteAssists } from '@hpx-it/developer-api-client';
import { isUndefined, omitBy, uniqueId } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';

const TARGET = '_blank';
const FEATURES = 'height=800,width=800,resizeable=yes';

export type OpenBookingWidgetInput = {
  clientId: string;
  remoteAssist?: remoteAssists.RemoteAssist;
  isMember?: boolean;
  assignTo?: string;
  showClientSelection?: boolean;
  audience?: 'technician' | 'agent';
  transferTo?: string;
  sessionNotes?: string;
  parentRemoteAssistRelation?:
    | 'CUSTOMER_CALLBACK'
    | 'TRANSFER'
    | 'FUTURE_FOLLOW_UP';
};

type OpenBookingWidgetResponse = {
  id: string;
  assignTo: string;
};

export const useOpenBookingWidget = () => {
  const { channel, channelName } = useMemo(() => {
    const channelName = uniqueId(`booking-`);
    return { channel: new BroadcastChannel(channelName), channelName };
  }, []);

  useEffect(() => {
    return () => {
      channel.close();
    };
  }, [channel]);

  return useCallback(
    (input: OpenBookingWidgetInput) => {
      return new Promise<OpenBookingWidgetResponse | null>((resolve) => {
        const remoteAssist = input.remoteAssist;

        const urlParams = new URLSearchParams(
          omitBy(
            {
              audience: input.audience ?? 'agent',
              client: input.clientId,
              assignTo: input.assignTo,
              transferTo: input.transferTo,
              sessionNotes: input.sessionNotes,
              showCloseButton: 'true',
              isSixteenOrOlder: input.parentRemoteAssistRelation ? true : false,
              ...(input.showClientSelection && {
                showPage: 'CLIENT_SELECTION',
              }),
              ...(remoteAssist && {
                trade: remoteAssist.service_code.id,
                reference: remoteAssist.reference,
                customerFirstName:
                  remoteAssist.attendees[0].contact?.first_name,
                customerLastName: remoteAssist.attendees[0].contact?.last_name,
                customerEmail: remoteAssist.attendees[0].contact?.email,
                customerPhone: remoteAssist.attendees[0].contact?.phone,
                customerNotes: remoteAssist.notes,
                customerPostalCode: remoteAssist.service_location,
                customerReference: remoteAssist.attendees[0].reference,
                customerIsMember: remoteAssist.attendees[0].is_member,
                parentRemoteAssistId: remoteAssist.id,
                parentRemoteAssistRelation: input.parentRemoteAssistRelation,
                showMemberToggle: true,
              }),
              channelName,
            },
            isUndefined,
          ) as Record<string, string>,
        );

        const pagesToSkip = [
          'WELCOME',
          ...(input.transferTo ? [] : ['AVAILABILITY_CHECK']),
          ...(remoteAssist
            ? ['TRADE_SELECTION', 'CUSTOMER_INPUT', 'NOTES_INPUT']
            : []),
        ];
        pagesToSkip.forEach((page) => {
          urlParams.append('skipPage', page);
        });

        const bookingWindow = window.open(
          `${window.location.origin}/booking?${urlParams.toString()}`,
          TARGET,
          FEATURES,
        );

        channel.addEventListener('message', async function (event) {
          if (event.data?.message === 'HomeXRABookingWidget-CloseButtonClick') {
            bookingWindow?.close();
            resolve(null);
          } else if (
            event.data?.message === 'HomeXRABookingWidget-RemoteAssistBooked'
          ) {
            resolve(event.data.data);
          }
        });
      });
    },
    [channel, channelName],
  );
};
