import { DeveloperApiClient, equipment } from '@hpx-it/developer-api-client';
import { ReactNode, createContext, useCallback, useContext } from 'react';

import {
  DeveloperApiClientContext,
  throwDeveloperApiUnauthenticatedError,
} from '..';

type EquipmentApiContextProps = {
  getEquipment: DeveloperApiClient['getEquipment'];
  getBrands: DeveloperApiClient['getBrands'];
};

export type EquipmentApiProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: EquipmentApiContextProps = {
  getEquipment: () => throwDeveloperApiUnauthenticatedError(),
  getBrands: () => throwDeveloperApiUnauthenticatedError(),
};

export const EquipmentApiContext =
  createContext<EquipmentApiContextProps>(DEFAULT_CONTEXT);

export const EquipmentApiProvider = ({
  children,
}: EquipmentApiProviderProps) => {
  const { getDeveloperApiClient } = useContext(DeveloperApiClientContext);

  const getEquipment = useCallback(
    async (input: equipment.GetEquipmentInput) =>
      await getDeveloperApiClient().getEquipment(input),
    [getDeveloperApiClient],
  );

  const getBrands = useCallback(
    async (input: equipment.GetBrandsInput) =>
      await getDeveloperApiClient().getBrands(input),
    [getDeveloperApiClient],
  );

  return (
    <EquipmentApiContext.Provider value={{ getEquipment, getBrands }}>
      {children}
    </EquipmentApiContext.Provider>
  );
};
