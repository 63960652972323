import { ReactNode } from 'react';

import {
  AttachmentApiProvider,
  AvailabilityApiProvider,
  ClientApiProvider,
  CommentsApiProvider,
  DeveloperApiClientProvider,
  DiagnosisApiProvider,
  EquipmentApiProvider,
  LabelApiProvider,
  MessagingApiProvider,
  ProductInfoApiProvider,
  QueueApiProvider,
  RemoteAssistApiProvider,
  SignedLinksApiProvider,
  TechnicianApiProvider,
  TradeApiProvider,
  VideoCallingApiProvider,
} from '.';

export type DeveloperApiProviderProps = {
  children: ReactNode;
};

export const DeveloperApiProvider = ({
  children,
}: DeveloperApiProviderProps) => {
  return (
    <DeveloperApiClientProvider>
      <AttachmentApiProvider>
        <AvailabilityApiProvider>
          <ClientApiProvider>
            <CommentsApiProvider>
              <DiagnosisApiProvider>
                <EquipmentApiProvider>
                  <LabelApiProvider>
                    <MessagingApiProvider>
                      <ProductInfoApiProvider>
                        <QueueApiProvider>
                          <RemoteAssistApiProvider>
                            <SignedLinksApiProvider>
                              <TechnicianApiProvider>
                                <TradeApiProvider>
                                  <VideoCallingApiProvider>
                                    {children}
                                  </VideoCallingApiProvider>
                                </TradeApiProvider>
                              </TechnicianApiProvider>
                            </SignedLinksApiProvider>
                          </RemoteAssistApiProvider>
                        </QueueApiProvider>
                      </ProductInfoApiProvider>
                    </MessagingApiProvider>
                  </LabelApiProvider>
                </EquipmentApiProvider>
              </DiagnosisApiProvider>
            </CommentsApiProvider>
          </ClientApiProvider>
        </AvailabilityApiProvider>
      </AttachmentApiProvider>
    </DeveloperApiClientProvider>
  );
};
