import { DeveloperApiClient, messaging } from '@hpx-it/developer-api-client';
import { ReactNode, createContext, useCallback, useContext } from 'react';

import {
  DeveloperApiClientContext,
  throwDeveloperApiUnauthenticatedError,
} from '..';

type MessagingApiContextProps = {
  sendSmsReminder: DeveloperApiClient['sendSmsReminder'];
};

export type MessagingApiProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: MessagingApiContextProps = {
  sendSmsReminder: () => throwDeveloperApiUnauthenticatedError(),
};

export const MessagingApiContext =
  createContext<MessagingApiContextProps>(DEFAULT_CONTEXT);

export const MessagingApiProvider = ({
  children,
}: MessagingApiProviderProps) => {
  const { getDeveloperApiClient } = useContext(DeveloperApiClientContext);

  const sendSmsReminder = useCallback(
    async (input: messaging.SendSmsReminder) =>
      await getDeveloperApiClient().sendSmsReminder(input),
    [getDeveloperApiClient],
  );

  return (
    <MessagingApiContext.Provider value={{ sendSmsReminder }}>
      {children}
    </MessagingApiContext.Provider>
  );
};
