import { debounce, startsWith } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

// storage prefixes - each one must be unique
const LOCAL_STORAGE_PREFIX = 'localStorage';
const REMOTE_ASSIST_SESSION_STORAGE_PREFIX = 'raSessionStorage';

// clears session storage by removing all items with the storage prefix
const clearSessionStorage = (storagePrefix: string) => {
  Object.keys(window.localStorage)
    .filter((key) => startsWith(key, storagePrefix))
    .forEach((key) => {
      window.localStorage.removeItem(key);
    });
};

export type SetValueFunction<T> = (
  value: T | ((old?: T | null) => T),
  options?: { debounce?: boolean },
) => void;
// takes a value from storage and automatically updates on any change
// optional sessionId to handle sessions
function useStorage<T>(
  storagePrefix: string,
  key: string,
  initialValue: T,
  sessionId?: string,
): [T, SetValueFunction<T>] {
  const readValue = useCallback((): T => {
    // when getting an item, check it is for the correct sessionId if one exists
    if (sessionId) {
      const storedSessionId = window.localStorage.getItem(
        `${storagePrefix}-id`,
      );
      // if incorrect sessionId, clear all items from the session and update the storedSessionId
      if (sessionId !== storedSessionId) {
        clearSessionStorage(storagePrefix);
        window.localStorage.setItem(`${storagePrefix}-id`, sessionId);
      }
    }
    try {
      const item = window.localStorage.getItem(`${storagePrefix}.${key}`);
      return item ? JSON.parse(item) : initialValue;
    } catch {
      // if an error occurs when getting or parsing item, return initial value
      return initialValue;
    }
    // initialValue shouldn't change so remove it as a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storagePrefix, key, sessionId]);

  // state to store our value and pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(readValue);

  // function to set value in storage and optionally trigger the update event for other hooks
  const setValueInStorage = useCallback(
    (value: T, blockUpdateEventDispatch?: boolean) => {
      // save to local storage
      window.localStorage.setItem(
        `${storagePrefix}.${key}`,
        JSON.stringify(value),
      );
      if (blockUpdateEventDispatch) {
        // dispatch a custom event so every useStorage hook of this type is notified
        window.dispatchEvent(new Event(storagePrefix));
      }
    },
    [key, storagePrefix],
  );

  // debounced version of the setValueInStorage function to stop many writes to local storage
  const debouncedSetValueInStorage = useMemo(
    () => debounce(setValueInStorage, 1000),
    [setValueInStorage],
  );

  // return a wrapped version of useState's setter function that persists the new value to storage.
  const setValue: SetValueFunction<T> = useCallback(
    (value: T | ((old?: T | null) => T), options = { debounce: true }) => {
      // save state
      setStoredValue((oldValue) => {
        // allow value to be a function so we have the same API as useState
        const newValue = value instanceof Function ? value(oldValue) : value;
        // save to storage
        options.debounce
          ? debouncedSetValueInStorage(newValue)
          : setValueInStorage(newValue);
        return newValue;
      });
    },
    [debouncedSetValueInStorage, setValueInStorage],
  );

  // persist changes to storage in cases where debounced function hasn't triggered
  useEffect(() => {
    const handleUnload = () => {
      setStoredValue((value) => {
        // block triggering update event as document is closing anyway
        setValueInStorage(value, true);
        return value;
      });
    };

    // triggered on the document closing
    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [setValueInStorage]);

  // keep all instances of hook in sync
  useEffect(() => {
    const handleStorageChange = () => {
      setStoredValue(readValue());
    };

    // this only works for other documents, not the current one
    window.addEventListener('storage', handleStorageChange);
    // this is a custom event, triggered in setValue
    window.addEventListener(storagePrefix, handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener(storagePrefix, handleStorageChange);
    };
  }, [readValue, storagePrefix]);
  return [storedValue, setValue];
}

export function useLocalStorage<T>(key: string, initialValue: T) {
  // no sessionId as local storage should persist indefinitely
  return useStorage<T>(LOCAL_STORAGE_PREFIX, key, initialValue);
}

export function useRemoteAssistSessionStorage<T>(
  remoteAssistId: string,
  key: string,
  initialValue: T,
) {
  return useStorage<T>(
    REMOTE_ASSIST_SESSION_STORAGE_PREFIX,
    key,
    initialValue,
    remoteAssistId,
  );
}
