import { diagnosis } from '@hpx-it/developer-api-client';
import { Grid } from '@mui/material';
import { DiagnosisApiContext, RemoteAssistApiContext } from 'api';
import {
  DisplayEquipmentFields,
  EditableEquipmentActions,
  EditableEquipmentFields,
  ErrorMessage,
  Unknown,
} from 'components';
import { isEqual, isUndefined, omitBy } from 'lodash';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

type EquipmentAccordionDisplayProps = {
  diagnosis?: diagnosis.Diagnosis;
  paddingIfNoEquipment?: string; // padding around the 'n/a' text needs to vary depending on where this component is placed
  editEquipment?: boolean;
  setEditEquipment?: Dispatch<SetStateAction<boolean>>;
  remoteAssistId: string;
  refetchRemoteAssist?: () => Promise<unknown>;
};

export const EquipmentAccordionDisplay = ({
  diagnosis,
  paddingIfNoEquipment = '',
  editEquipment,
  setEditEquipment,
  remoteAssistId,
  refetchRemoteAssist,
}: EquipmentAccordionDisplayProps) => {
  const { createDiagnosis, updateDiagnosis } = useContext(DiagnosisApiContext);
  const { updateRemoteAssist } = useContext(RemoteAssistApiContext);

  const hasEquipment = diagnosis && diagnosis.equipment.length > 0;

  const emptyEquipmentValue: diagnosis.Equipment = {
    name: '',
    type: '',
    brand: '',
    model_number: '',
    error_code: '',
    serial_number: '',
    equipment_notes: '',
  };

  const currentEquipmentValues: diagnosis.Equipment[] | undefined =
    useMemo(() => {
      return diagnosis?.equipment?.map((equip) => ({
        name: equip?.name,
        type: equip?.type,
        brand: equip?.brand,
        model_number: equip?.model_number,
        error_code: equip.error_code,
        serial_number: equip.serial_number,
        equipment_notes: equip.equipment_notes,
      }));
    }, [diagnosis]);

  const [equipmentUpdateInput, setEquipmentUpdateInput] = useState<
    diagnosis.Equipment[] | undefined
  >(currentEquipmentValues);
  const [loadingEquipment, setLoadingEquipment] = useState<boolean>(false);
  const [equipmentCountChanged, setEquipmentCountChanged] =
    useState<boolean>(false);
  const [errorMessageUpdateEquipment, setErrorMessageUpdateEquipment] =
    useState<string>('');

  const equipmentUpdateMatchesCurrent = useMemo(() => {
    return isEqual(equipmentUpdateInput, currentEquipmentValues);
  }, [equipmentUpdateInput, currentEquipmentValues]);

  const disableSave = useMemo(
    () =>
      (equipmentUpdateMatchesCurrent && !equipmentCountChanged) ||
      loadingEquipment,
    [equipmentUpdateMatchesCurrent, equipmentCountChanged, loadingEquipment],
  );

  const onEquipmentChange =
    (variableName: keyof diagnosis.Equipment, index: number) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const newEquipmentUpdateInput = equipmentUpdateInput?.map(
        (equipment, i) => {
          if (i !== index) {
            return equipment;
          }
          return {
            ...equipment,
            [variableName]:
              event.target.value.trim() === '' ? null : event.target.value,
          };
        },
      );
      setEquipmentUpdateInput(newEquipmentUpdateInput);
    };

  const cancelEquipment = () => {
    if (!setEditEquipment) {
      return;
    }
    setEditEquipment(false);
    setErrorMessageUpdateEquipment('');
    setEquipmentUpdateInput(currentEquipmentValues);
  };

  const saveEquipment = async () => {
    if (!refetchRemoteAssist || !setEditEquipment || !equipmentUpdateInput) {
      return;
    }
    try {
      setLoadingEquipment(true);
      const newEquipment = equipmentUpdateInput.map((equipment) =>
        omitBy(equipment, (value) => isUndefined(value) || value === ''),
      );

      const upsertedDiagnosis = diagnosis?.id
        ? await updateDiagnosis({
            id: diagnosis.id,
            equipment: newEquipment,
          })
        : await createDiagnosis({
            equipment: newEquipment,
          });

      await updateRemoteAssist({
        id: remoteAssistId,
        diagnosis_id: upsertedDiagnosis.id,
      });

      await refetchRemoteAssist();
      setEditEquipment(false);
      setErrorMessageUpdateEquipment('');
    } catch (error) {
      setErrorMessageUpdateEquipment('Unable to update session details');
    } finally {
      setLoadingEquipment(false);
      setEquipmentCountChanged(false);
    }
  };

  const addEquipment = () => {
    const newEquipment = [...(equipmentUpdateInput ?? [])];
    newEquipment?.push(emptyEquipmentValue);
    setEquipmentUpdateInput(newEquipment);
    setEquipmentCountChanged(true);
  };

  const deleteEquipment = (index: number) => {
    const newEquipment = [...(equipmentUpdateInput ?? [])];
    newEquipment?.splice(index, 1);
    setEquipmentUpdateInput(newEquipment);
    setEquipmentCountChanged(true);
  };

  if (!hasEquipment && !editEquipment) {
    return (
      <Grid
        sx={{
          padding: paddingIfNoEquipment,
        }}
      >
        <Unknown />
      </Grid>
    );
  }

  return (
    <>
      {editEquipment && errorMessageUpdateEquipment && (
        <Grid pb={2}>
          <ErrorMessage
            message={errorMessageUpdateEquipment}
            variant="subtitle1"
            iconSize={18}
          />
        </Grid>
      )}

      {editEquipment &&
        equipmentUpdateInput &&
        equipmentUpdateInput?.map((equip, index) => (
          <EditableEquipmentFields
            equip={equip}
            index={index}
            onEquipmentChange={onEquipmentChange}
            loadingEquipment={loadingEquipment}
            deleteEquipment={deleteEquipment}
          />
        ))}

      {!editEquipment &&
        diagnosis &&
        diagnosis.equipment.map((equip) => (
          <DisplayEquipmentFields equip={equip} />
        ))}

      {editEquipment && setEditEquipment && (
        <EditableEquipmentActions
          loadingEquipment={loadingEquipment}
          addEquipment={addEquipment}
          saveEquipment={saveEquipment}
          cancelEquipment={cancelEquipment}
          disableSave={disableSave}
        />
      )}
    </>
  );
};
