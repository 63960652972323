import { diagnosis } from '@hpx-it/developer-api-client';
import { Dispatch, SetStateAction, createContext, useState } from 'react';

type CreateEquipmentContextProps = {
  equipmentToCreate: diagnosis.Equipment | undefined;
  setEquipmentToCreate: Dispatch<
    SetStateAction<diagnosis.Equipment | undefined>
  >;
};

type CreateEquipmentProviderProps = {
  children: React.ReactNode;
};

const DEFAULT_CONTEXT: CreateEquipmentContextProps = {
  equipmentToCreate: undefined,
  setEquipmentToCreate: () => {},
};

export const CreateEquipmentContext =
  createContext<CreateEquipmentContextProps>(DEFAULT_CONTEXT);

export const CreateEquipmentProvider = ({
  children,
}: CreateEquipmentProviderProps) => {
  const [equipmentToCreate, setEquipmentToCreate] =
    useState<diagnosis.Equipment>();

  return (
    <CreateEquipmentContext.Provider
      value={{
        equipmentToCreate,
        setEquipmentToCreate,
      }}
    >
      {children}
    </CreateEquipmentContext.Provider>
  );
};
