import { DeveloperApiClient, videoCalling } from '@hpx-it/developer-api-client';
import { ReactNode, createContext, useCallback, useContext } from 'react';

import {
  DeveloperApiClientContext,
  throwDeveloperApiUnauthenticatedError,
} from '..';

type VideoCallingApiContextProps = {
  getTechnicianToken: DeveloperApiClient['getTechnicianToken'];
  getRecordingURLs: DeveloperApiClient['getRecordingURLs'];
  getSummaries: DeveloperApiClient['getSummaries'];
  getTranscripts: DeveloperApiClient['getTranscripts'];
  getVideoCallingClientConfig: DeveloperApiClient['getVideoCallingClientConfig'];
};

export type VideoCallingApiProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: VideoCallingApiContextProps = {
  getTechnicianToken: () => throwDeveloperApiUnauthenticatedError(),
  getRecordingURLs: () => throwDeveloperApiUnauthenticatedError(),
  getSummaries: () => throwDeveloperApiUnauthenticatedError(),
  getTranscripts: () => throwDeveloperApiUnauthenticatedError(),
  getVideoCallingClientConfig: () => throwDeveloperApiUnauthenticatedError(),
};

export const VideoCallingApiContext =
  createContext<VideoCallingApiContextProps>(DEFAULT_CONTEXT);

export const VideoCallingApiProvider = ({
  children,
}: VideoCallingApiProviderProps) => {
  const { getDeveloperApiClient } = useContext(DeveloperApiClientContext);

  const getTechnicianToken = useCallback(
    async (input: videoCalling.GetTechnicianTokenInput) =>
      await getDeveloperApiClient().getTechnicianToken(input),
    [getDeveloperApiClient],
  );

  const getRecordingURLs = useCallback(
    async (input: videoCalling.GetRecordingURLsInput) =>
      await getDeveloperApiClient().getRecordingURLs(input),
    [getDeveloperApiClient],
  );

  const getTranscripts = useCallback(
    async (input: videoCalling.GetTranscriptsInput) =>
      await getDeveloperApiClient().getTranscripts(input),
    [getDeveloperApiClient],
  );

  const getSummaries = useCallback(
    async (input: videoCalling.GetSummariesInput) =>
      await getDeveloperApiClient().getSummaries(input),
    [getDeveloperApiClient],
  );

  const getVideoCallingClientConfig = useCallback(
    async () => await getDeveloperApiClient().getVideoCallingClientConfig(),
    [getDeveloperApiClient],
  );

  return (
    <VideoCallingApiContext.Provider
      value={{
        getTechnicianToken,
        getRecordingURLs,
        getSummaries,
        getTranscripts,
        getVideoCallingClientConfig,
      }}
    >
      {children}
    </VideoCallingApiContext.Provider>
  );
};
