import {
  DeveloperApiClient,
  labels,
} from '@hpx-it/developer-api-client';
import { ReactNode, createContext, useCallback, useContext } from 'react';

import {
  DeveloperApiClientContext,
  throwDeveloperApiUnauthenticatedError,
} from '..';

type LabelApiContextProps = {
  getInformation: DeveloperApiClient['getLabelInformationWithAttachmentId'];
};

export type LabelApiProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: LabelApiContextProps = {
  getInformation: () => throwDeveloperApiUnauthenticatedError(),
};

export const LabelApiContext =
  createContext<LabelApiContextProps>(DEFAULT_CONTEXT);

export const LabelApiProvider = ({ children }: LabelApiProviderProps) => {
  const { getDeveloperApiClient } = useContext(DeveloperApiClientContext);

  const getInformation = useCallback(
    async (
      input: labels.GetLabelInformationWithAttachmentId,
    ) =>
      await getDeveloperApiClient().getLabelInformationWithAttachmentId(input),
    [getDeveloperApiClient],
  );

  return (
    <LabelApiContext.Provider value={{ getInformation }}>
      {children}
    </LabelApiContext.Provider>
  );
};
