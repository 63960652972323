import { ChevronLeft, Menu } from '@mui/icons-material';
import {
  Box,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/material';
import {
  ClientContext,
  TechnicianContext,
  UserContext,
  ViewsContext,
} from 'contexts';
import {
  Dispatch,
  SetStateAction,
  lazy,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CLIENT_ID_TO_NAME_MAP,
  LEFT_NAV_WIDTH,
  LEFT_NAV_WIDTH_EXPANDED,
  View,
  hasHPASupply,
  hasMultipleDemandClients,
  useOpenBookingWidget,
} from 'utils';

import { LeftNavigationListItem } from './LeftNavigationListItem';

const { REACT_APP_HPA_CLIENT_ID } = process.env;

type LeftNavigationProps = {
  isLHSMenuOpen: boolean;
  setIsLHSMenuOpen: Dispatch<SetStateAction<boolean>>;
};
export const LeftNavigation = ({
  isLHSMenuOpen,
  setIsLHSMenuOpen,
}: LeftNavigationProps) => {
  const navigate = useNavigate();
  const { userClientId } = useContext(UserContext);
  const { branding } = useContext(ClientContext);
  const { currentRemoteAssist, technician, refetch } =
    useContext(TechnicianContext);
  const { views } = useContext(ViewsContext);
  const openPage = useLocation().pathname;
  const openBookingWidget = useOpenBookingWidget();

  const viewsToDisplay = useMemo(
    () =>
      views
        .filter(
          (view): view is View & { sidebar: NonNullable<View['sidebar']> } =>
            !!view.sidebar,
        )
        .filter(
          (view) =>
            view.sidebar?.showSidebar?.({
              hasCurrentRemoteAssist: !!currentRemoteAssist,
            }) ?? true,
        ),
    [views, currentRemoteAssist],
  );

  const showTechnicianBookingOptions = useMemo(
    () =>
      !!technician &&
      !viewsToDisplay.find((view) => view.id === 'remote-assist-call'),
    [technician, viewsToDisplay],
  );

  const directBook = useCallback(async () => {
    const ra = await openBookingWidget({
      clientId: userClientId,
      transferTo: userClientId,
      assignTo: technician?.id,
      showClientSelection:
        userClientId === REACT_APP_HPA_CLIENT_ID && hasMultipleDemandClients(),
      audience: 'technician',
    });
    if (ra && ra.assignTo === technician?.id) {
      await refetch();
      navigate('/remote-assist-call');
    }
  }, [navigate, openBookingWidget, refetch, technician?.id, userClientId]);

  const showHPABookingOption = useMemo(
    () =>
      showTechnicianBookingOptions &&
      userClientId !== REACT_APP_HPA_CLIENT_ID &&
      hasHPASupply(),
    [showTechnicianBookingOptions, userClientId],
  );

  return (
    <Drawer
      anchor="left"
      variant="permanent"
      PaperProps={{
        sx: {
          width: isLHSMenuOpen ? LEFT_NAV_WIDTH_EXPANDED : LEFT_NAV_WIDTH,
          boxShadow: 3,
          transition: 'all 0.75s',
          overflowX: 'hidden',
        },
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        direction="column"
        minHeight="100%"
      >
        <List>
          <ListItem
            sx={{
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem',
            }}
          >
            {isLHSMenuOpen && (
              <Grid item xs={11}>
                <Typography>{CLIENT_ID_TO_NAME_MAP[userClientId]}</Typography>
              </Grid>
            )}
            <Grid item xs={isLHSMenuOpen ? 1 : 12}>
              <ListItemButton
                disableRipple
                onClick={() => {
                  setIsLHSMenuOpen(!isLHSMenuOpen);
                }}
                sx={{
                  justifyContent: 'center',
                  padding: '0.5rem 0',
                  borderRadius: '1rem',
                }}
              >
                {isLHSMenuOpen ? <ChevronLeft /> : <Menu />}
              </ListItemButton>
            </Grid>
          </ListItem>

          {showTechnicianBookingOptions && (
            <LeftNavigationListItem
              buttonText="Start Call"
              onClick={directBook}
              border={!showHPABookingOption}
              selected={false}
              isLHSMenuOpen={isLHSMenuOpen}
              Icon={lazy(() => import('@mui/icons-material/AddIcCall'))}
            />
          )}

          {viewsToDisplay.map((view) => (
            <LeftNavigationListItem
              key={view.id}
              buttonText={
                typeof view.sidebar.name === 'string'
                  ? view.sidebar.name
                  : view.sidebar.name()
              }
              onClick={() => {
                if (openPage !== view.path) {
                  setIsLHSMenuOpen(false);
                }
                navigate(view.path);
              }}
              border={view.id === 'remote-assist-call'}
              selected={openPage === view.path}
              isLHSMenuOpen={isLHSMenuOpen}
              Icon={view.sidebar.icon}
            />
          ))}
        </List>

        {isLHSMenuOpen && branding?.assetUrls?.logo && (
          <Box
            sx={{
              width: '14rem',
              position: 'static',
              bottom: 30,
            }}
          >
            <img
              src={branding.assetUrls.logo}
              width="160px"
              alt=""
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            />
          </Box>
        )}
      </Grid>
    </Drawer>
  );
};
