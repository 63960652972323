import { logger } from '@hpx-it/react-app';
import { Box, CircularProgress, Theme, ThemeProvider } from '@mui/material';
import {
  ClientApiContext,
  DeveloperApiProvider,
  TechnicianApiContext,
  TradeApiContext,
} from 'api';
import {
  LeftNavigation,
  ToastMessageContainer,
  TopNavigation,
} from 'components';
import { ConfigureModal } from 'components/configure-modal';
import {
  ClientContext,
  ClientProvider,
  DevicesContext,
  UserContext,
} from 'contexts';
import {
  TechnicianContext,
  TechnicianProvider,
} from 'contexts/TechnicianContext';
import { ViewsContext, ViewsProvider } from 'contexts/ViewsContext';
import { useInterval } from 'hooks';
import {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  LEFT_NAV_WIDTH,
  LEFT_NAV_WIDTH_EXPANDED,
  TOP_NAV_HEIGHT,
  generateTheme,
  pushToastMessage,
} from 'utils';
import * as constants from 'utils/constants';

import { Loading } from './loading';

const VIEW_HEIGHT = `calc(100vh - ${TOP_NAV_HEIGHT})`;
const TOAST_WARNING_TIMES = [30, 15, 10, 5, 3, 2, 1];

function ViewRoutes() {
  const { views } = useContext(ViewsContext);

  return (
    <Routes>
      {views.map((view) => (
        <Route key={view.id} path={view.path} element={<view.component />} />
      ))}
    </Routes>
  );
}

function ViewRoutesContainer() {
  return (
    <Box height="100%" width="100%" id="view-container">
      <ViewRoutes />
    </Box>
  );
}

function ViewTemplate() {
  const { isUserAuthenticated } = useContext(UserContext);
  const { showConfigure, setShowConfigure } = useContext(DevicesContext);
  const [isLHSMenuOpen, setIsLHSMenuOpen] = useState(false);
  const { technician } = useContext(TechnicianContext);
  const { showNav } = useContext(ViewsContext);
  const { isLoadingBrand } = useContext(ClientContext);
  const {
    getClient,
    getClients,
    getClientsProducts,
    getClientsProductOutcomes,
  } = useContext(ClientApiContext);
  const { getTrades, getServiceCodes } = useContext(TradeApiContext);
  const { getTechnicians } = useContext(TechnicianApiContext);
  const [constantsLoaded, setConstantsLoaded] = useState<boolean>(false);
  const [, setMinutesWithIncorrectCommit] = useState<number>(0);
  const lastCheckedVersion = useRef<Date | undefined>(undefined);
  const intervalRef = useRef<number | NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isUserAuthenticated) {
      // load constants
      (async () => {
        await constants.init(
          getClients,
          getClient,
          getTrades,
          getTechnicians,
          getServiceCodes,
          getClientsProducts,
          getClientsProductOutcomes,
        );
        setConstantsLoaded(true);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAuthenticated]);

  const pushIncorrectCommitToast = useCallback(() => {
    const maxMinutes = Math.max(...TOAST_WARNING_TIMES);
    setMinutesWithIncorrectCommit((minutesElapsed) => {
      if (minutesElapsed >= maxMinutes) {
        window.location.reload();
        return 0;
      }

      if (TOAST_WARNING_TIMES.includes(maxMinutes - minutesElapsed)) {
        pushToastMessage({
          content: `Fieldguide will refresh in ${
            maxMinutes - minutesElapsed
          } minutes`,
          variant: 'warning',
          action: {
            text: 'Refresh now',
            function: () => window.location.reload(),
          },
        });
      }

      return minutesElapsed + 1;
    });
  }, [setMinutesWithIncorrectCommit]);

  const startCommitToasts = useCallback(() => {
    if (intervalRef.current) {
      return;
    }

    pushIncorrectCommitToast();
    intervalRef.current = setInterval(() => {
      pushIncorrectCommitToast();
    }, 60000);
  }, [pushIncorrectCommitToast]);

  const checkVersion = useCallback(async () => {
    try {
      const res = await fetch(`${window.location.origin}/functions/commit-ref`);
      const json = await res.json();
      const commit_ref = json.commit_ref;
      if (!commit_ref) {
        return;
      }

      if (commit_ref === process.env.REACT_APP_COMMIT_REF) {
        lastCheckedVersion.current = new Date();
        return;
      }

      const twoHoursAgo = new Date();
      twoHoursAgo.setHours(twoHoursAgo.getHours() - 2);
      if (
        !lastCheckedVersion.current ||
        lastCheckedVersion.current <= twoHoursAgo
      ) {
        window.location.reload();
      }

      lastCheckedVersion.current = new Date();
      startCommitToasts();
    } catch (error) {
      logger.error('Failed to fetch commit reference', { message: error });
    }
  }, [startCommitToasts]);

  useInterval(() => {
    checkVersion();
  }, 30000);

  useEffect(() => {
    checkVersion();
  }, [checkVersion]);

  if (isLoadingBrand || !constantsLoaded) {
    return <Loading loading={isLoadingBrand || !constantsLoaded} />;
  }

  return (
    <>
      {showNav && (
        <LeftNavigation
          isLHSMenuOpen={isLHSMenuOpen}
          setIsLHSMenuOpen={setIsLHSMenuOpen}
        />
      )}
      <Box
        style={{
          marginLeft: showNav
            ? isLHSMenuOpen
              ? LEFT_NAV_WIDTH_EXPANDED
              : LEFT_NAV_WIDTH
            : 0,
          transition: 'all 0.75s',
          height: '100vh',
        }}
      >
        {showNav && <TopNavigation technician={technician} />}
        <ToastMessageContainer />
        <Suspense
          fallback={
            <Box
              width="100%"
              height={VIEW_HEIGHT}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress size={100} />
            </Box>
          }
        >
          <Box height={showNav ? VIEW_HEIGHT : '100vh'} width="100%">
            <ViewRoutesContainer />
          </Box>
        </Suspense>
        {showConfigure && (
          <ConfigureModal onClose={() => setShowConfigure(false)} />
        )}
      </Box>
    </>
  );
}

export function FieldguideRoutes() {
  const { isUserAuthenticated, userLoading } = useContext(UserContext);
  const [theme, setTheme] = useState<Theme>(generateTheme());

  if (!isUserAuthenticated || userLoading) {
    return <Loading loading={userLoading || !isUserAuthenticated} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <DeveloperApiProvider>
        <TechnicianProvider>
          <ViewsProvider>
            <ClientProvider setTheme={setTheme}>
              <ViewTemplate />
            </ClientProvider>
          </ViewsProvider>
        </TechnicianProvider>
      </DeveloperApiProvider>
    </ThemeProvider>
  );
}
