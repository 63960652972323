import { DeveloperApiClient, diagnosis } from '@hpx-it/developer-api-client';
import { ReactNode, createContext, useCallback, useContext } from 'react';

import {
  DeveloperApiClientContext,
  throwDeveloperApiUnauthenticatedError,
} from '..';

type DiagnosisApiContextProps = {
  createDiagnosis: DeveloperApiClient['createDiagnosis'];
  updateDiagnosis: DeveloperApiClient['updateDiagnosis'];
};

export type DiagnosisApiProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: DiagnosisApiContextProps = {
  createDiagnosis: () => throwDeveloperApiUnauthenticatedError(),
  updateDiagnosis: () => throwDeveloperApiUnauthenticatedError(),
};

export const DiagnosisApiContext =
  createContext<DiagnosisApiContextProps>(DEFAULT_CONTEXT);

export const DiagnosisApiProvider = ({
  children,
}: DiagnosisApiProviderProps) => {
  const { getDeveloperApiClient } = useContext(DeveloperApiClientContext);

  const createDiagnosis = useCallback(
    async (input: diagnosis.CreateDiagnosisInput) =>
      await getDeveloperApiClient().createDiagnosis(input),
    [getDeveloperApiClient],
  );

  const updateDiagnosis = useCallback(
    async (input: diagnosis.UpdateDiagnosisInput) =>
      await getDeveloperApiClient().updateDiagnosis(input),
    [getDeveloperApiClient],
  );

  return (
    <DiagnosisApiContext.Provider
      value={{
        createDiagnosis,
        updateDiagnosis,
      }}
    >
      {children}
    </DiagnosisApiContext.Provider>
  );
};
