import CloseIcon from '@mui/icons-material/Close';
import { Alert, Button, Grid, IconButton, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  ToastMessage,
  deleteToastMessage,
  getDisplayToastMessages,
  onToastMessageChange,
} from 'utils';

export const ToastMessageContainer = (): JSX.Element => {
  const [messages, setMessages] = useState<ToastMessage[]>(
    getDisplayToastMessages(),
  );

  useEffect(() => {
    onToastMessageChange((newMessages) => {
      setMessages(newMessages);
    });
  }, []);

  const content = messages.map((message: ToastMessage) => {
    const variant = message.variant ?? 'info';

    return (
      <Snackbar
        open={true}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={
          message.autohide
            ? Math.min(
                (message.shownTime / (message.autohide - 500)) * 100,
                100,
              )
            : undefined
        }
        key={message.id}
      >
        <Alert
          variant="filled"
          severity={variant}
          sx={{ width: '100%', alignItems: 'center' }}
        >
          {message.content}
          {message.action && (
            <Button
              onClick={async () => {
                await message.action?.function();
                deleteToastMessage(message.id);
              }}
              sx={{
                color: 'rgba(0, 0, 0, 0.7)',
                textDecorationLine: 'underline',
                '&:hover': {
                  textDecorationLine: 'underline',
                },
              }}
            >
              {message.action.text}
            </Button>
          )}
          {message.closeable !== false && (
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={() => deleteToastMessage(message.id)}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Alert>
      </Snackbar>
    );
  });
  return <Grid zIndex={5}>{content}</Grid>;
};
