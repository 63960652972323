import { diagnosis } from '@hpx-it/developer-api-client';
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { Label, Unknown } from 'components';

type DisplayEquipmentFieldsProps = {
  equip: diagnosis.Equipment;
};

export const DisplayEquipmentFields = ({
  equip,
}: DisplayEquipmentFieldsProps) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="equipment-content"
        id="equipment-header"
      >
        {equip.name ? <Typography>{equip.name}</Typography> : <Unknown />}
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <Table
            sx={{
              '& td': {
                padding: '0.5rem',
                fontSize: '0.75rem',
                fontWeight: 500,
                border: '0',
                align: 'left',
              },
              '& td:nth-child(odd)': {
                width: '20%',
              },
              '& td:nth-child(even)': {
                width: '30%',
              },
            }}
            aria-label="equipment table"
          >
            <TableBody>
              <TableRow>
                <TableCell>
                  <Label label="Type" />
                </TableCell>
                <TableCell>{equip.type ? equip.type : <Unknown />}</TableCell>
                <TableCell>
                  <Label label="Brand" />
                </TableCell>
                <TableCell>{equip.brand ? equip.brand : <Unknown />}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Label label="Error Code" />
                </TableCell>
                <TableCell>{equip.error_code ?? <Unknown />}</TableCell>
                <TableCell>
                  <Label label="Model #" />
                </TableCell>
                <TableCell>{equip.model_number ?? <Unknown />}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Label label="Serial #" />
                </TableCell>
                <TableCell>{equip.serial_number ?? <Unknown />}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Label label="Notes" />
                </TableCell>
                <TableCell colSpan={3}>
                  {equip.equipment_notes ?? <Unknown />}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};
