import { SearchOffOutlined } from '@mui/icons-material';
import { Box, CircularProgress, Grid, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import { CreateEquipmentCard, ExtractedData } from '.';
import { labels } from '@hpx-it/developer-api-client';

type handleCreateEquipmentParams = {
  modelNumber?: string;
  serialNumber?: string;
  logo?: string;
};

type EquipmentCardProps = {
  labelInformation: labels.GetLabelInformationResponse | undefined;
  error: boolean;
  handleCreateEquipment: ({
    modelNumber,
    serialNumber,
    logo,
  }: handleCreateEquipmentParams) => void;
};

export const EquipmentCard = ({
  labelInformation,
  error,
  handleCreateEquipment,
}: EquipmentCardProps) => {
  const [modelNumber, setModelNumber] = useState<string>('');
  const [serialNumber, setSerialNumber] = useState<string>('');
  const [logo, setLogo] = useState<string>('');
  const [isDetecting, setIsDetecting] = useState<boolean>(true);

  const theme = useTheme();

  const noDataExtracted = useMemo(() => {
    return !modelNumber && !serialNumber && !logo;
  }, [modelNumber, serialNumber, logo]);

  // If error returned from useQuery, set all to false
  // to indicate on UI that couldn't process image
  useEffect(() => {
    if (error) {
      setModelNumber('');
      setSerialNumber('');
      setLogo('');
      setIsDetecting(false);
    }
  }, [error]);

  useEffect(() => {
    if (labelInformation) {
      setModelNumber(labelInformation.model_number_value ?? '');
      setSerialNumber(labelInformation.serial_number_value ?? '');
      setLogo(labelInformation?.possible_brands?.[0] ?? '');
      setIsDetecting(false);
    }
  }, [labelInformation, setModelNumber, setSerialNumber, setLogo]);

  return (
    <Grid
      container
      item
      xs={6}
      border={`1px solid ${theme.palette.grey[300]}`}
      borderRadius="0.5rem"
      sx={{
        bgcolor: theme.palette.common.white,
      }}
    >
      {/* Loading Section*/}
      {noDataExtracted && isDetecting && (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: '60vh' }}
        >
          <CircularProgress size={50} />
        </Grid>
      )}

      {/* No Data Extracted from Image Section */}
      {noDataExtracted && !isDetecting && (
        <>
          <Box
            width="100%"
            height="2rem"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <p>
              No characters were able to be extracted from this image. Please
              try another image.
            </p>
          </Box>
          <Box
            width="100%"
            height="2rem"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <SearchOffOutlined fontSize="large" color="inherit" />
          </Box>
        </>
      )}

      {/* Not Loading/Some Data Extracted Section */}
      {!noDataExtracted && !isDetecting && (
        <Grid container direction="row" margin="0 auto" spacing={2}>
          <CreateEquipmentCard
            modelNumber={modelNumber}
            serialNumber={serialNumber}
            logo={logo}
            handleCreateEquipment={handleCreateEquipment}
          />

          {/* Model No., Serial No. and Logo */}
          <ExtractedData
            modelNumber={modelNumber}
            setModelNumber={setModelNumber}
            serialNumber={serialNumber}
            setSerialNumber={setSerialNumber}
            logo={logo}
            setLogo={setLogo}
          />
        </Grid>
      )}
    </Grid>
  );
};
