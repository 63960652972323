import {
  DeveloperApiClient,
  remoteAssists,
} from '@hpx-it/developer-api-client';
import { ReactNode, createContext, useCallback, useContext } from 'react';

import {
  DeveloperApiClientContext,
  throwDeveloperApiUnauthenticatedError,
} from '..';

type RemoteAssistApiContextProps = {
  getRemoteAssists: DeveloperApiClient['getRemoteAssists'];
  getRemoteAssist: DeveloperApiClient['getRemoteAssist'];
  cancelRemoteAssist: DeveloperApiClient['cancelRemoteAssist'];
  completeRemoteAssist: DeveloperApiClient['completeRemoteAssist'];
  updateRemoteAssist: DeveloperApiClient['updateRemoteAssist'];
};

export type RemoteAssistApiProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: RemoteAssistApiContextProps = {
  getRemoteAssists: () => throwDeveloperApiUnauthenticatedError(),
  getRemoteAssist: () => throwDeveloperApiUnauthenticatedError(),
  cancelRemoteAssist: () => throwDeveloperApiUnauthenticatedError(),
  completeRemoteAssist: () => throwDeveloperApiUnauthenticatedError(),
  updateRemoteAssist: () => throwDeveloperApiUnauthenticatedError(),
};

export const RemoteAssistApiContext =
  createContext<RemoteAssistApiContextProps>(DEFAULT_CONTEXT);

export const RemoteAssistApiProvider = ({
  children,
}: RemoteAssistApiProviderProps) => {
  const { getDeveloperApiClient } = useContext(DeveloperApiClientContext);

  const getRemoteAssists = useCallback(
    async (input: remoteAssists.GetRemoteAssistsInput) =>
      await getDeveloperApiClient().getRemoteAssists(input),
    [getDeveloperApiClient],
  );

  const getRemoteAssist = useCallback(
    async (input: remoteAssists.GetRemoteAssistInput) =>
      await getDeveloperApiClient().getRemoteAssist(input),
    [getDeveloperApiClient],
  );

  const cancelRemoteAssist = useCallback(
    async (input: remoteAssists.CancelRemoteAssistInput) =>
      await getDeveloperApiClient().cancelRemoteAssist(input),
    [getDeveloperApiClient],
  );

  const completeRemoteAssist = useCallback(
    async (input: remoteAssists.CompleteRemoteAssistInput) =>
      await getDeveloperApiClient().completeRemoteAssist(input),
    [getDeveloperApiClient],
  );

  const updateRemoteAssist = useCallback(
    async (input: remoteAssists.UpdateRemoteAssistInput) =>
      await getDeveloperApiClient().updateRemoteAssist(input),
    [getDeveloperApiClient],
  );

  return (
    <RemoteAssistApiContext.Provider
      value={{
        getRemoteAssists,
        getRemoteAssist,
        cancelRemoteAssist,
        completeRemoteAssist,
        updateRemoteAssist,
      }}
    >
      {children}
    </RemoteAssistApiContext.Provider>
  );
};
