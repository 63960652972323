import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { Button } from '@mui/material';
import { AttachmentUrl } from 'pages/technician-queue-dashboard/types';
import Carousel from 'react-material-ui-carousel';

import {
  TicketCarouselButtonDivStyle,
  TicketCarouselButtonStyle,
  carouselImageStyle,
  carouselItemStyle,
  carouselVideoStyle,
  playIconStyle,
  videoContainerStyle,
} from './style';

export const TicketAttachmentCarousel = ({
  attachmentUrls,
  onAttachmentClick,
}: {
  attachmentUrls: AttachmentUrl[];
  onAttachmentClick: (attachment: AttachmentUrl) => void;
}) => (
  <Carousel
    autoPlay={false}
    navButtonsAlwaysVisible={true}
    animation="fade"
    height="calc(10rem - 29px)"
  >
    {attachmentUrls.map((attachment, index) => {
      return (
        <div key={index} style={carouselItemStyle}>
          <MediaDialogContent
            attachment={attachment}
            onAttachmentClick={onAttachmentClick}
            index={index}
          />
        </div>
      );
    })}
  </Carousel>
);

const MediaDialogContent = ({
  attachment,
  onAttachmentClick,
  index,
}: {
  attachment: AttachmentUrl;
  onAttachmentClick: (attachment: AttachmentUrl) => void;
  index: number;
}) => {
  switch (attachment.mediaType) {
    case 'video':
      return (
        <div style={videoContainerStyle}>
          <video
            src={attachment.url}
            controls={false}
            style={carouselVideoStyle}
            onClick={() => onAttachmentClick(attachment)}
          />
          <PlayCircleFilledIcon style={playIconStyle} /> {/* Play icon */}
        </div>
      );
    case 'image':
      return (
        <img
          src={attachment.url}
          alt={`Slide ${index}`}
          style={carouselImageStyle}
          onClick={() => onAttachmentClick(attachment)}
        />
      );
    default:
      return (
        <div style={TicketCarouselButtonDivStyle}>
          <Button
            onClick={() => onAttachmentClick(attachment)}
            style={TicketCarouselButtonStyle}
            variant="contained"
          >
            View {attachment.mediaType}
          </Button>
        </div>
      );
  }
};
